import React, { useState } from "react";
import { TextField, Button } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { limits } from "../../Dashboard/StationMap/limits";
import { getDataFromFacade, postDataToFacade } from "../../../services/facade_service"

interface InputsProps {
    cols: number,
    callBack: (kpis: any) => void
}

export function Inputs({ cols, callBack }: InputsProps) {

    const [simulationType, setSimulationType] = React.useState<string>('Auto');
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [inputDisabled, setInputDisabled] = React.useState<boolean>(true);
    const [usuariosValue, setUsuariosValue] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [simulateDisabled, setSimulateDisabled] = useState<boolean>(false);

    // Disable TextField when simulation type is set to Auto
    React.useEffect(() => {
        setInputDisabled(simulationType === 'Auto');
    }, [simulationType]);

    // Set simulation type on select button change
    const handleSimulationTypeChange = (event: SelectChangeEvent) => {
        setSimulationType(event.target.value as string);
    };

    // Get the tracked persons from the facade service
    async function getTrackedPersonPositions() {

        const twins: any[] = await getDataFromFacade("/api/twins");
        const trackedPersons: any[] = [];

        twins.forEach(twin => {
            if (twin.dtId.startsWith('TrackedPerson') && twin.zoneName === 'zona_vestibulo_completo'
                && twin.position && twin.position.x !== null && twin.position.x !== undefined) {
                const person = {
                    id: twin.dtId,
                    position: twin.position
                }
                trackedPersons.push(person);
            }
        })
        return trackedPersons;
    }

    // Start automatic simulation
    async function startAutoSimulation() {
        try {
            // Get the tracked persons and build the body
            const trackedPersons = await getTrackedPersonPositions();
            if (trackedPersons.length > 0) {
                const body = {
                    simulation_id: 'Adif',
                    scenario_file: 'chamartin.scenario',
                    pedestrians: trackedPersons
                }
                // Call the simulation endpoint from the facade service
                const response = await postDataToFacade('/api/simulation', body);
                console.log(response);
                return response;
            } else {
                return {}
            }
        } catch (error) {
            console.error("Simulation error:", error);
        } finally {
            setIsLoading(false);
        }
    }

    // Start manual simulation
    async function startManualSimulation(users: string) {
        // Get the user quantity from the TextField value
        const userQty = parseInt(users, 10);
        // Get the camera coordinate limits
        const minCamX = limits.zona_vestibulo_completo.minCamX;
        const maxCamX = limits.zona_vestibulo_completo.maxCamX;
        const minCamY = limits.zona_vestibulo_completo.minCamY;
        const maxCamY = limits.zona_vestibulo_completo.maxCamY;
        const userList = []
        // Create the desired Tracked persons with random positions
        for (let i = 0; i < userQty; i++) {
            const user = {
                id: `TrackedPerson${i.toFixed()}`,
                position: {
                    x: Math.random() * (maxCamX - minCamX) + minCamX,
                    y: Math.random() * (maxCamY - minCamY) + minCamY,
                }
            }
            userList.push(user);
        }
        try {
            // Call the simulation endpoint from the facade service
            if (userList.length > 0) {
                const body = {
                    simulation_id: 'Adif',
                    scenario_file: 'chamartin.scenario',
                    pedestrians: userList
                }
                const response = await postDataToFacade('/api/simulation', body);
                console.log(response);
                return response;
            } else {
                return {}
            }
        } catch (error) {
            console.error("Simulation error:", error);
        } finally {
            setIsLoading(false);
        }
    }

    // Check TextField errors
    function check_input_Errors() {
        const userQty = usuariosValue.trim();
        if (!/^\d+$/.test(userQty)) {
            setError('Por favor introduzca un número entero');
            return true;
        }
        return false;
    }

    // Call the apropiate simulation
    async function startSimulation(simulationType: string) {

        if (simulationType === 'Manual' && check_input_Errors()) {
            return;
        }
        setIsLoading(true);
        setSimulateDisabled(true);
        let kpis = {}
        switch (simulationType) {
            case 'Auto':
                kpis = await startAutoSimulation();
                break;
            case 'Manual':
                kpis = await startManualSimulation(usuariosValue);
                break;
            default:
                break;
        }
        callBack(kpis);
    }

    // Set the TextField value and remove errors while typing
    function handleUsuariosChange(event: React.ChangeEvent<HTMLInputElement>) {
        setUsuariosValue(event.target.value);
        setError('');
    }

    function newSimulation() {
        setSimulateDisabled(false);
        setUsuariosValue('');
        callBack({});
    }

    return (
        <div className={`SimulationInputs ${cols === 1 ? "single" : cols > 1 ? "double" : "hidden"}`}>
            <div className="Card" style={{ position: "relative", display: 'flex', flexDirection: 'column', height: '100%' }}>
                <div className="Title" style={{ height: '15%' }}>Parámetros de simulación</div>
                <Box sx={{ minWidth: 80, color: 'white', height: '15%', paddingLeft: '1rem' }}>
                    <FormControl fullWidth sx={{ maxWidth: 160 }}>
                        <InputLabel id="demo-simple-select-label" sx={{ color: 'white' }}>Tipo de simulación</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={simulationType}
                            label="Tipo de simulación"
                            onChange={handleSimulationTypeChange}
                            sx={{
                                height: '2.5rem',
                                color: 'white',
                                '.MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'white',
                                },
                                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'white',
                                },
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: 'white',
                                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                },
                                '.MuiSvgIcon-root': {
                                    color: 'white',
                                },
                            }}
                        >
                            <MenuItem value={'Auto'}>Automática</MenuItem>
                            <MenuItem value={'Manual'}>Manual</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                {isLoading ? (
                    <div style={{ height: '55%', paddingLeft: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                            <CircularProgress />
                            <h6>Cargando simulación...</h6>
                            <h6>Tiempo aproximado: 30 segundos</h6>
                        </Box>
                    </div>
                ) : (
                    <div style={{ height: '55%', paddingLeft: '2rem', display: 'flex', flexDirection: 'column' }}>
                        <TextField
                            id="standard-basic"
                            label="Usuarios"
                            variant="standard"
                            disabled={inputDisabled}
                            value={usuariosValue}
                            onChange={handleUsuariosChange}
                            error={!!error}
                            helperText={error}
                            sx={{
                                width: '8rem',
                                input: {
                                    color: inputDisabled ? 'grey' : 'white',
                                },
                                '& .MuiInput-underline:before': {
                                    borderBottomColor: inputDisabled ? 'grey' : 'white',
                                },
                                '& .MuiInput-underline:after': {
                                    borderBottomColor: inputDisabled ? 'grey' : 'white',
                                },
                                '& .MuiInputLabel-root': {
                                    color: inputDisabled ? 'grey' : 'white'
                                },
                            }}
                        />
                    </div>
                )}

                <div className="Submit" style={{ height: '15%' }}>
                <Button
                        disabled={isLoading}
                        sx={{
                            color: 'white',
                            borderColor: 'white',
                            position: "absolute",
                            bottom: '1rem',
                            left: '1rem',
                            '&:hover': {
                                borderColor: 'white',
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            },
                            '&.Mui-focused': {
                                borderColor: 'white',
                            }
                        }}
                        onClick={() => { newSimulation() }}
                    >
                        Nueva simulacion
                    </Button>
                    <Button
                        variant="outlined"
                        disabled={simulateDisabled}
                        sx={{
                            color: 'white',
                            borderColor: 'white',
                            position: "absolute",
                            bottom: '1rem',
                            right: '1rem',
                            '&:hover': {
                                borderColor: 'white',
                                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            },
                            '&.Mui-focused': {
                                borderColor: 'white',
                            }
                        }}
                        onClick={() => { startSimulation(simulationType) }}
                    >
                        Simular
                    </Button>
                </div>
            </div>
        </div>
    );
}