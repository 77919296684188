import React from "react";
import { Button } from "@mui/material"

import DashboardIcon from '@mui/icons-material/Dashboard';
import MonitorIcon from '@mui/icons-material/Monitor';
import AnalyticsIcon from '@mui/icons-material/Analytics';

import adif_logo from "../../assets/logo/logo_adif.png"

import { useNavigate } from "react-router-dom";

interface SidebarProps{
    current_route:string,
    change_route:(new_route:string)=>void
}

export function Sidebar({current_route, change_route}:SidebarProps){

    const navigate = useNavigate()

    const handleRedirect = (new_route:string) => {
        change_route(new_route)
        navigate(`/admin/${new_route}`, {replace:true})
    }
    return (
        <div className="Sidebar">
            <img src={adif_logo} alt="Logo empresa Adif" />
            <div className="SidebarButtons">
                <Button 
                    variant="outlined" 
                    startIcon={<MonitorIcon />} 
                    className={current_route === "dashboard" ? "selected":""}
                    onClick={()=>{handleRedirect("dashboard")}} >
                    Monitor
                </Button>
                <Button 
                    variant="outlined" 
                    sx={{opacity: 0.7}}
                    startIcon={<AnalyticsIcon />} 
                    className={current_route === "simulator" ? "selected":""}
                    onClick={()=>{handleRedirect("simulator")}} 
                    >
                    Simulador
                </Button>
            </div>
        </div>
    )
}