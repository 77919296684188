// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arrivals-container {
    margin-top: 1rem;
    height: 100%; 
    width: 100% !important;
    padding: 0rem 1rem 1rem 1rem !important;
    overflow-y: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: .5rem;
    scrollbar-width: thin;
    scrollbar-color: rgb(0, 20, 52) transparent; 
}



.train-info {
    width: 90%;
    background-color: rgb(0, 20, 52);
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem 1rem 1rem;
    border-radius: 20px;
}

.first-row {
    display: flex;
    justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/TrainsInfo/TrainsInfo.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;IACZ,sBAAsB;IACtB,uCAAuC;IACvC,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,UAAU;IACV,qBAAqB;IACrB,2CAA2C;AAC/C;;;;AAIA;IACI,UAAU;IACV,gCAAgC;IAChC,aAAa;IACb,sBAAsB;IACtB,4BAA4B;IAC5B,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC","sourcesContent":[".arrivals-container {\n    margin-top: 1rem;\n    height: 100%; \n    width: 100% !important;\n    padding: 0rem 1rem 1rem 1rem !important;\n    overflow-y: auto;\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n    gap: .5rem;\n    scrollbar-width: thin;\n    scrollbar-color: rgb(0, 20, 52) transparent; \n}\n\n\n\n.train-info {\n    width: 90%;\n    background-color: rgb(0, 20, 52);\n    display: flex;\n    flex-direction: column;\n    padding: 1rem 1rem 1rem 1rem;\n    border-radius: 20px;\n}\n\n.first-row {\n    display: flex;\n    justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
