import React, { useEffect, useState } from 'react';
import map from '../../../assets/maps/Andenes.png'
import { DiagramProps } from '../../../interfaces';

// Función para generar un color aleatorio basado en una paleta de colores en formato RGB
function getRandomColorFromPalette(id: number) {
    const colors = [
        { r: 0, g: 152, b: 87 },
        { r: 0, g: 138, b: 107 },
        { r: 0, g: 122, b: 118 },
        { r: 0, g: 106, b: 118 },
        { r: 17, g: 88, b: 107 },
        { r: 47, g: 72, b: 88 }
    ]
    const index = id % colors.length;
    const baseColor = colors[index];

    // Añadir un ruido aleatorio basado en el entero
    const randomFactor = () => Math.floor((Math.sin(id) * 10000) % 40) - 20; // determinístico

    const newR = Math.min(255, Math.max(0, baseColor.r + randomFactor()));
    const newG = Math.min(255, Math.max(0, baseColor.g + randomFactor()));
    const newB = Math.min(255, Math.max(0, baseColor.b + randomFactor()));

    // Retornar el nuevo color RGB
    return { r: newR, g: newG, b: newB };

}

function rgbToCss(rgb: any) {
    return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;
}

export function AndenDiagram({ data, personData }: DiagramProps) {


    useEffect(() => {

        const container = document.querySelector('.anden.diagram');

        if (container instanceof HTMLElement) {
            container.innerHTML = '';
            const image = new Image();
            image.src = map;
            container.appendChild(image);

            personData.map(point => {
                const alertContainer = document.createElement('div');
                alertContainer.className = 'person-container ' + point.value.toString();
                const randomColor = getRandomColorFromPalette(point.value);
                alertContainer.style.backgroundColor = rgbToCss(randomColor);
                alertContainer.style.left = `${point.x - 12}px`
                alertContainer.style.top = `${point.y - 12}px`

                container.appendChild(alertContainer);
            })
        }
    }, [personData]);

    return (
        <div className='diagram-container'>
            <div className="anden diagram"></div>
        </div>
    );
}
