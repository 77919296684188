import React, { useEffect, useState } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { AndenDiagram } from "./AndenDiagram";
import { VestibuloDiagram } from "./VestibuloDiagram";
import { ClustersDataObject, heatMapPoint, ParsedTrackedPersons } from "../../../interfaces";
import { getDataFromFacade } from "../../../services/facade_service";
import { limits } from "./limits";
import "./StationMap.css";

interface StationMapProps {
  cols: number;
}

const INTERVAL = 1000;
const WS_URI = "ws:localhost/ws?services=modelling.output";
var userCount = 0;

const { scale: scaleXCompleto, offset: offsetXCompleto } = calculateTransformationParams(
  limits.zona_vestibulo_completo.minCamX,
  limits.zona_vestibulo_completo.maxCamX,
  limits.zona_vestibulo_completo.minOwnX,
  limits.zona_vestibulo_completo.maxOwnX
);
const { scale: scaleYCompleto, offset: offsetYCompleto } = calculateTransformationParams(
  limits.zona_vestibulo_completo.minCamY,
  limits.zona_vestibulo_completo.maxCamY,
  limits.zona_vestibulo_completo.minOwnY,
  limits.zona_vestibulo_completo.maxOwnY
);
const { scale: scaleXVestibuloAnden9, offset: offsetXVestibuloAnden9 } = calculateTransformationParams(
  limits.zone_vestibulo_anden9.minCamX,
  limits.zone_vestibulo_anden9.maxCamX,
  limits.zone_vestibulo_anden9.minOwnX,
  limits.zone_vestibulo_anden9.maxOwnX
);
const { scale: scaleYVestibuloAnden9, offset: offsetYVestibuloAnden9 } = calculateTransformationParams(
  limits.zone_vestibulo_anden9.minCamY,
  limits.zone_vestibulo_anden9.maxCamY,
  limits.zone_vestibulo_anden9.minOwnY,
  limits.zone_vestibulo_anden9.maxOwnY
);
const { scale: scaleXAnden9, offset: offsetXAnden9 } = calculateTransformationParams(
  limits.zone_anden9.minCamX,
  limits.zone_anden9.maxCamX,
  limits.zone_anden9.minOwnX,
  limits.zone_anden9.maxOwnX
);
const { scale: scaleYAnden9, offset: offsetYAnden9 } = calculateTransformationParams(
  limits.zone_anden9.minCamY,
  limits.zone_anden9.maxCamY,
  limits.zone_anden9.minOwnY,
  limits.zone_anden9.maxOwnY
);
const { scale: scaleXAnden10, offset: offsetXAnden10 } = calculateTransformationParams(
  limits.zone_anden10.minCamX,
  limits.zone_anden10.maxCamX,
  limits.zone_anden10.minOwnX,
  limits.zone_anden10.maxOwnX
);
const { scale: scaleYAnden10, offset: offsetYAnden10 } = calculateTransformationParams(
  limits.zone_anden10.minCamY,
  limits.zone_anden10.maxCamY,
  limits.zone_anden10.minOwnY,
  limits.zone_anden10.maxOwnY
);
const { scale: scaleXAnden11, offset: offsetXAnden11 } = calculateTransformationParams(
  limits.zone_anden11.minCamX,
  limits.zone_anden11.maxCamX,
  limits.zone_anden11.minOwnX,
  limits.zone_anden11.maxOwnX
);
const { scale: scaleYAnden11, offset: offsetYAnden11 } = calculateTransformationParams(
  limits.zone_anden11.minCamY,
  limits.zone_anden11.maxCamY,
  limits.zone_anden11.minOwnY,
  limits.zone_anden11.maxOwnY
);

function calculateTransformationParams(
  minCam: number,
  maxCam: number,
  minOwn: number,
  maxOwn: number
) {
  const scale = (maxOwn - minOwn) / (maxCam - minCam);
  const offset = minOwn - scale * minCam;
  return { scale, offset };
}

function transformCoordinate(coord: number, scale: number, offset: number): number {
  return scale * coord + offset;
}

function parseHeatMapData(data: ClustersDataObject): heatMapPoint[] | [] {
  const heatMapData: heatMapPoint[] = [];

  Object.keys(data).forEach((key) => {
    const newX = transformCoordinate(data[key].centroid[0], -scaleXCompleto, offsetXCompleto);
    const newY = transformCoordinate(data[key].centroid[1], scaleYCompleto, offsetYCompleto);

    const newPoint: heatMapPoint = {
      x: Math.floor(newX),
      y: Math.floor(newY),
      value: data[key].intensity,
    };
    heatMapData.push(newPoint);
  });
  return heatMapData;
}

function parseTrackedPersonData(data: any[]): ParsedTrackedPersons {
  const personData: ParsedTrackedPersons = {
    "zona_vestibulo_completo": [],
    "zone_vestibulo_anden9": [],
    "zone_vestibulo_anden10": [],
    "zone_vestibulo_anden11": [],
    "zone_anden9": [],
    "zone_anden10": [],
    "zone_anden11": [],
  };
  console.log("DATA: ", data)
  data.forEach(key => {
    let newX;
    let newY;
    if (key.zoneName == "zone_vestibulo_anden9") {
      newX = transformCoordinate(key.position.x, -scaleXVestibuloAnden9, offsetXVestibuloAnden9);
      newY = transformCoordinate(key.position.y, scaleYVestibuloAnden9, offsetYVestibuloAnden9);
      const id = parseInt(key.dtId.replace("TrackedPerson", ""))
      const newPoint: heatMapPoint = {
        x: newX,
        y: newY,
        value: id,
      };
      personData.zone_vestibulo_anden9.push(newPoint);
    } else if (key.zoneName == "zone_anden9") {
      newX = transformCoordinate(key.position.x, -scaleXAnden9, offsetXAnden9);
      newY = transformCoordinate(key.position.y, scaleYAnden9, offsetYAnden9);
      const id = parseInt(key.dtId.replace("TrackedPerson", ""))
      const newPoint: heatMapPoint = {
        x: newX,
        y: newY,
        value: id,
      };
      personData.zone_anden9.push(newPoint);
    } else if (key.zoneName == "zone_anden10") {
      newX = transformCoordinate(key.position.x, -scaleXAnden10, offsetXAnden10);
      newY = transformCoordinate(key.position.y, scaleYAnden10, offsetYAnden10);
      const id = parseInt(key.dtId.replace("TrackedPerson", ""))
      const newPoint: heatMapPoint = {
        x: newX,
        y: newY,
        value: id,
      };
      personData.zone_anden10.push(newPoint);
    } else if (key.zoneName == "zone_anden11") {
      newX = transformCoordinate(key.position.x, -scaleXAnden11, offsetXAnden11);
      newY = transformCoordinate(key.position.y, scaleYAnden11, offsetYAnden11);
      const id = parseInt(key.dtId.replace("TrackedPerson", ""))
      const newPoint: heatMapPoint = {
        x: newX,
        y: newY,
        value: id,
      };
      personData.zone_anden11.push(newPoint);
    }
    else {
      newX = transformCoordinate(key.position.x, -scaleXCompleto, offsetXCompleto);
      newY = transformCoordinate(key.position.y, scaleYCompleto, offsetYCompleto);
      const id = parseInt(key.dtId.replace("TrackedPerson", ""))
      const newPoint: heatMapPoint = {
        x: newX,
        y: newY,
        value: id,
      };
      personData.zona_vestibulo_completo.push(newPoint);
    }
  });
  return personData;
}

export function StationMap({ cols }: StationMapProps) {
  const [selectedLayer, setSelectedLayer] = useState("vestibulo");
  const [heatMapData, setHeatMapData] = useState<heatMapPoint[] | []>([]);
  const [vestibuloData, setVestibuloData] = useState<heatMapPoint[] | []>([]);
  const [andenesData, setAndenesData] = useState<heatMapPoint[] | []>([]);


  const handleLayerChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setSelectedLayer(newAlignment);
  };

  useEffect(() => {
    async function fetchData() {
      // Get the clusters
      const clusters = await getDataFromFacade("/api/clusters");
      // Parse the clusters data
      const parsedData = parseHeatMapData(clusters);
      // Get the twins
      const twins = await getDataFromFacade("/api/twins");
      const trackedPersons = [];
      // Count the TrackedPersons
      for (const twin of twins) {
        if (twin.dtId.startsWith("TrackedPerson") && twin.position.x) {
          trackedPersons.push(twin);
        }
      }
      const parsedTrackedPerson = parseTrackedPersonData(trackedPersons)
      const vestData = parsedTrackedPerson.zona_vestibulo_completo.concat(parsedTrackedPerson.zone_vestibulo_anden9);
      setVestibuloData(vestData);
      const andData = parsedTrackedPerson.zone_anden9.concat(parsedTrackedPerson.zone_anden10, parsedTrackedPerson.zone_anden11);
      setAndenesData(andData);
      setHeatMapData(parsedData);
    }
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className={`StationMap ${cols === 1 ? "single" : cols > 1 ? "double" : "hidden"}`}>
      <div className="Card" style={{ position: 'relative', height: '93vh' }}>
        <div className="toggle-button-container">
          <ToggleButtonGroup
            color="primary"
            value={selectedLayer}
            exclusive
            onChange={handleLayerChange}
            aria-label="Platform"
            sx={{ backgroundColor: "#00a35e", color: "white" }}
          >
            <ToggleButton
              value="vestibulo"
              sx={{
                color: selectedLayer === "vestibulo" ? "white" : "black",
                backgroundColor:
                  selectedLayer === "vestibulo" ? "#00a35e" : "white",
                "&.Mui-selected": {
                  backgroundColor: "#00a35e",
                  color: "white",
                },
                "&.Mui-selected:hover": {
                  backgroundColor: "#007a48",
                  color: "white",
                },
                "&:hover": {
                  backgroundColor:
                    selectedLayer === "vestibulo" ? "#007a48" : "lightgray",
                },
              }}
            >
              Vestíbulo
            </ToggleButton>
            <ToggleButton
              value="anden"
              sx={{
                color: selectedLayer === "anden" ? "white" : "black",
                backgroundColor:
                  selectedLayer === "anden" ? "#00a35e" : "white",
                "&.Mui-selected": {
                  backgroundColor: "#00a35e",
                  color: "white",
                },
                "&.Mui-selected:hover": {
                  backgroundColor: "#007a48",
                  color: "white",
                },
                "&:hover": {
                  backgroundColor:
                    selectedLayer === "anden" ? "#007a48" : "lightgray",
                },
              }}
            >
              Andén
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        {selectedLayer === "vestibulo" && (
          <VestibuloDiagram data={heatMapData} personData={vestibuloData} />
        )}
        {selectedLayer === "anden" && (
          <AndenDiagram data={heatMapData} personData={andenesData} />
        )}
      </div>
    </div>
  );
}
