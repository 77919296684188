import { useState, useEffect } from 'react';
import { arrivalsRawData, arrivalsParsedData } from '../../../interfaces';
import { getDataFromFacade } from '../../../services/facade_service';
import { stationCodes } from './StationCodes';
import warning from '../../../assets/logo/danger.png'
import './TrainsInfo.css'

export function TrainsInfo() {

  const [arrivals, setArrivals] = useState<any[]>([])

  useEffect(() => {
    async function getTrainsData() {
      // Fetch all the arrivals
      const data: arrivalsRawData = await getDataFromFacade('/api/trains/arrivals/avldmd');

      if(!data) {
        console.log("Error fetching trains data");
        return;
      }

      // Initialice
      const nextArrivals: arrivalsParsedData[] = [];
      // Get the actual timestamp
      const now = Date.now();
      // Iterate through all the arrivals list
      data.commercialPaths.forEach(element => {
        const plannedTime = element?.passthroughStep?.arrivalPassthroughStepSides?.plannedTime;
        // Get the arrivals of the following 15 minutes
        if (plannedTime >= now && plannedTime <= (now + (30 * 60 * 1000))) {
          
          const originStationCode = element?.commercialPathInfo?.commercialOriginStationCode;
          const destStationCode = element?.commercialPathInfo?.commercialDestinationStationCode;

          nextArrivals.push({
            originStationName: stationCodes[originStationCode],
            destStationName: stationCodes[destStationCode],
            plannedTime: `${Math.ceil((plannedTime - now) / 60000)}min`,
            plannedPlatform: element?.passthroughStep?.arrivalPassthroughStepSides?.plannedPlatform,
          });
        }
      });

      setArrivals(nextArrivals);
    }

    // Fetch the trains data on the first render
    getTrainsData();

    // Fetch the trains arrivals data every 60 seconds
    const intervalId = setInterval(() => {
      getTrainsData();
    }, 60000);

    return () => clearInterval(intervalId);
  }, [])


  return (
    <>
      <div className={`Data single`}>
        <div className="Card">
          <div className="Title" style={{ display: 'flex', justifyContent: 'space-between' }}>
            Alertas - Llegadas de trenes
          </div>
          <div className="Body" style={{ height: '90%', width: '100%' }}>
            <div className='arrivals-container'>
              {arrivals.length > 0 &&
                <>
                  {arrivals.map(element => {
                    return (
                      <div className='train-info'>
                        <div className='first-row'>
                          <a>{element.originStationName} - {element.destStationName}</a>
                          <a>{element.plannedTime}</a>
                        </div>
                        <a>Plataforma: {element.plannedPlatform}</a>
                      </div>
                    )
                  })}
                </>
              }
            </div>
          </div>
        </div>
      </div>

    </>
  );
};
