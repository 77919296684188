export const stationCodes: { [key: string]: string } = {
    "10000": "Madrid Príncipe Pío",
    "10001": "Aravaca",
    "10002": "Pozuelo",
    "10005": "Las Rozas",
    "10007": "Majadahonda",
    "10010": "El Barrial-Centro Comercial Pozuelo",
    "10100": "Pinar de las Rozas",
    "10101": "Las Matas",
    "10103": "Torrelodones",
    "10104": "Galapagar-La Navata",
    "10200": "Villalba de Guadarrama",
    "10201": "San Yago",
    "10202": "Las Zorreras-Navalquejigo",
    "10203": "El Escorial",
    "10204": "Zarzalejo",
    "10205": "Robledo de Chavela",
    "10206": "Santa María de la Alameda-Peguerinos",
    "10207": "Las Navas del Marqués",
    "10208": "Navalperal",
    "10209": "El Pimpollar",
    "10300": "Herradón-La Cañada",
    "10302": "Guimorcondo",
    "10400": "Ávila",
    "10409": "Arévalo",
    "10500": "Medina del Campo",
    "10501": "Pozaldez",
    "10502": "Matapozuelos",
    "10503": "Valdestillas",
    "10504": "Viana",
    "10600": "Valladolid Campo Grande",
    "10602": "Cabezón de Pisuerga",
    "10603": "Corcos-Aguilarejo",
    "10604": "Cubillas de Santa Marta",
    "10605": "Dueñas",
    "10610": "Valladolid Universidad",
    "11000": "Venta de Baños",
    "11004": "Magaz",
    "11006": "Quintana del Puente",
    "11009": "Villaquirán",
    "11014": "Burgos Rosa Manzano",
    "11109": "Briviesca",
    "11111": "Pancorbo",
    "11200": "Miranda de Ebro",
    "11203": "Manzanos",
    "11204": "La Puebla de Arganzón",
    "11205": "Nanclares/Langraiz",
    "11208": "Vitoria-Gasteiz",
    "11210": "Alegría-Dulantzi",
    "11212": "Agurain / Salvatierra de Álava",
    "11213": "Araia",
    "11300": "Altsasu",
    "11305": "Brinkola",
    "11306": "Legazpi",
    "11400": "Zumarraga",
    "11402": "Ormaiztegi",
    "11404": "Beasain",
    "11405": "Ordizia",
    "11406": "Itsasondo",
    "11407": "Legorreta",
    "11408": "Ikaztegieta",
    "11409": "Alegia",
    "11500": "Tolosa",
    "11501": "Tolosa Centro",
    "11502": "Anoeta",
    "11503": "Villabona-Zizurkil",
    "11504": "Andoain Centro",
    "11505": "Andoain",
    "11506": "Urnieta",
    "11507": "Hernani Centro",
    "11508": "Hernani",
    "11509": "Martutene",
    "11510": "Loiola",
    "11511": "Donostia / San Sebastián",
    "11512": "Gros",
    "11513": "Ategorrieta",
    "11514": "Herrera",
    "11515": "Pasaia",
    "11516": "Lezo-Errenteria",
    "11518": "Ventas de Irún",
    "11522": "Intxaurrondo",
    "11600": "Irún",
    "11602": "Hendaya",
    "12001": "Los Negrales",
    "12002": "Alpedrete",
    "12004": "Collado Mediano",
    "12005": "Los Molinos-Guadarrama",
    "12006": "Cercedilla",
    "12007": "Tablada",
    "12008": "Gudillos",
    "12009": "San Rafael",
    "12010": "El Espinar",
    "12011": "Los Ángeles de San Rafael",
    "12012": "Otero-Herreros",
    "12013": "Ortigosa del Monte",
    "12014": "Navas de Riofrío-La Losa",
    "12020": "Puerto de Navacerrada",
    "12023": "Los Cotos",
    "12100": "Segovia",
    "13100": "Orduña",
    "13101": "Amurrio",
    "13102": "Salbio",
    "13103": "Luiaondo",
    "13104": "Santa Cruz de Llodio",
    "13106": "Laudio / Llodio",
    "13107": "Areta",
    "13108": "Arbide",
    "13109": "Arrankudiaga",
    "13110": "Ugao-Miraballes",
    "13111": "Arrigorriaga",
    "13112": "Basauri",
    "13113": "Bidebieta-Basauri",
    "13114": "Ollargan",
    "13115": "Bakiola",
    "13116": "Iñarratxu",
    "13117": "Arakaldo",
    "13118": "Abaroa-San Miguel",
    "13119": "La Peña de Bilbao",
    "13120": "Miribilla",
    "13121": "Amurrio Iparralde",
    "13200": "Bilbao Intermodal Abando-Indalecio Prie.",
    "13205": "Zabalburu",
    "13206": "Ametzola",
    "13207": "Autonomia",
    "13208": "San Mamés",
    "13303": "Olabeaga",
    "13304": "Zorrotza",
    "13305": "Lutxana-Barakaldo",
    "13400": "Desertu-Barakaldo",
    "13401": "Sestao",
    "13402": "La Iberia",
    "13403": "Portugalete",
    "13404": "Peñota",
    "13405": "Santurtzi",
    "13501": "Galindo",
    "13502": "Trápaga",
    "13503": "Valle de Trápaga / Trapagaran",
    "13504": "Ortuella",
    "13505": "Putxeta",
    "13506": "Muskiz",
    "13507": "Urioste",
    "13508": "Gallarta",
    "13509": "Sagrada Familia",
    "14100": "Palencia",
    "14101": "Monzón de Campos",
    "14102": "Amusco",
    "14103": "Piña",
    "14104": "Frómista",
    "14107": "Osorno",
    "14108": "Espinosa de Villagonzalo",
    "14111": "Herrera de Pisuerga",
    "14112": "Alar del Rey",
    "14113": "Mave",
    "14114": "Aguilar de Campoo",
    "14115": "Quintanilla de las Torres",
    "14117": "El Carrión",
    "14200": "Mataporquera",
    "14202": "Reinosa",
    "14203": "Lantueno-Santiurde",
    "14204": "Pesquera",
    "14206": "Bárcena",
    "14207": "Molledo-Portolín",
    "14208": "Santa Cruz de Iguña",
    "14209": "Las Fraguas",
    "14210": "Los Corrales de Buelna",
    "14211": "Las Caldas de Besaya",
    "14212": "Viérnoles",
    "14213": "Torrelavega Tanos",
    "14214": "Sierrapando",
    "14215": "Zurita",
    "14216": "Renedo",
    "14217": "Parbayón",
    "14218": "Guarnizo",
    "14219": "Boo",
    "14220": "Maliaño",
    "14221": "Muriedas",
    "14223": "Santander",
    "14230": "Valdecilla",
    "14231": "Nueva Montaña",
    "14232": "Lombera",
    "14233": "Río Ebro",
    "14234": "Pujayo",
    "14235": "Arenas de Iguña",
    "14236": "Vioño",
    "14237": "Muriedas Bahía",
    "15001": "Grijota",
    "15003": "Becerril",
    "15004": "Paredes de Nava",
    "15006": "Cisneros",
    "15007": "Villada",
    "15008": "Grajal",
    "15009": "Sahagún",
    "15012": "El Burgo Ranero",
    "15014": "Santas Martas",
    "15015": "Palanquinos",
    "15100": "León",
    "15106": "La Robla",
    "15108": "La Pola de Gordón",
    "15109": "Santa Lucía",
    "15112": "Villamanín",
    "15113": "Busdongo",
    "15116": "Linares-Congostinas",
    "15118": "Puente de los Fierros",
    "15119": "La Frecha",
    "15120": "Campomanes",
    "15121": "La Cobertoria",
    "15122": "Pola de Lena",
    "15123": "Villallana",
    "15200": "Ujo",
    "15202": "Santullano",
    "15203": "Mieres Puente",
    "15205": "Ablaña",
    "15206": "La Pereda-Riosa",
    "15207": "Olloniego",
    "15208": "Soto de Rey",
    "15209": "Las Segadas",
    "15210": "El Caleyo",
    "15211": "Oviedo",
    "15212": "Lugones",
    "15214": "Lugo de Llanera-Base (APD)",
    "15217": "La Corredoria",
    "15218": "Llamaquique",
    "15300": "Lugo de Llanera",
    "15301": "Villabona de Asturias",
    "15302": "Serín",
    "15303": "Monteana",
    "15305": "Villabona Tabladiello",
    "15400": "Veriña",
    "15401": "Calzada de Asturias",
    "15410": "Gijón-Sanz Crespo",
    "16001": "Santa Eulalia de Manzaneda",
    "16002": "Tudela-Veguín",
    "16005": "Peña Rubia",
    "16006": "Barros",
    "16008": "La Felguera",
    "16009": "Sama",
    "16010": "Ciaño",
    "16011": "El Entrego",
    "16301": "Ferroñes",
    "16302": "Cancienes",
    "16400": "Nubledo",
    "16401": "Villalegre",
    "16402": "La Rocica",
    "16403": "Avilés",
    "16405": "San Juan de Nieva",
    "16408": "Los Campos",
    "17000": "Madrid Chamartín Clara Campoamor",
    "17001": "Fuencarral",
    "17003": "El Goloso",
    "17004": "Tres Cantos",
    "17005": "Colmenar Viejo",
    "17009": "Universidad-Cantoblanco",
    "18000": "Madrid Atocha Cercanías",
    "18001": "Madrid Recoletos",
    "18002": "Madrid Nuevos Ministerios",
    "18003": "Méndez Álvaro C1 C10",
    "18004": "Delicias",
    "18005": "Pirámides",
    "18101": "Sol",
    "19001": "Universidad Pontificia de Comillas",
    "19002": "Valdelasfuentes",
    "19003": "Alcobendas-San Sebastián de los Reyes",
    "20002": "Quintana-Raneros",
    "20005": "Veguellina",
    "20006": "Barrientos",
    "20007": "Nistal",
    "20008": "Villavante",
    "20100": "Astorga",
    "20102": "Vega-Magaz",
    "20103": "Porqueros",
    "20104": "Brañuelas",
    "20106": "La Granja",
    "20109": "Torre del Bierzo",
    "20111": "Bembibre",
    "20113": "San Miguel de las Dueñas",
    "20200": "Ponferrada",
    "20203": "Villadepalos",
    "20204": "Toral de los Vados",
    "20207": "Covas",
    "20208": "Quereño",
    "20210": "Sobradelo",
    "20211": "O Barco de Valdeorras",
    "20212": "Vilamartín de Valdeorras",
    "20213": "A Rúa-Petín",
    "20214": "Montefurado",
    "20216": "San Clodio-Quiroga",
    "20218": "A Pobra do Brollón",
    "20300": "Monforte de Lemos",
    "20305": "Sarria",
    "20306": "Pedrelo-Céltigos",
    "20309": "Lugo",
    "20310": "Rábade",
    "20312": "Baamonde",
    "20313": "Parga",
    "20314": "Guitiriz",
    "20316": "Teixeiro",
    "20317": "Curtis",
    "20318": "Piñoi",
    "20319": "Cesuras",
    "20320": "Oza dos Ríos",
    "20400": "Betanzos Infesta",
    "20402": "Cecebre",
    "20403": "Cambre",
    "20404": "O Burgo-Santiago",
    "20410": "Elviña-Universidade",
    "21001": "Betanzos Cidade",
    "21002": "Miño",
    "21003": "Perbes",
    "21004": "Pontedeume",
    "21005": "Cabanas",
    "21007": "Barallobre",
    "21008": "Perlío",
    "21009": "Neda",
    "21010": "Ferrol",
    "22001": "Canabal",
    "22002": "Areas",
    "22003": "Santo Estevo do Sil",
    "22004": "San Pedro do Sil",
    "22005": "Os Peares",
    "22006": "Barra de Miño",
    "22100": "Ourense",
    "22101": "Barbantes",
    "22102": "Ribadavia",
    "22103": "Filgueira",
    "22104": "Frieira",
    "22105": "Pousa-Crecente",
    "22106": "Arbo",
    "22107": "Sela",
    "22108": "As Neves",
    "22109": "Salvaterra",
    "22110": "Caldelas",
    "22200": "Guillarei",
    "22201": "O Porriño",
    "22300": "Redondela",
    "22308": "Vigo Guixar",
    "22401": "Tui",
    "22402": "Valença do Minho",
    "23000": "Redondela Picota",
    "23001": "Cesantes",
    "23002": "Arcade",
    "23004": "Pontevedra",
    "23005": "Portela",
    "23008": "Vilagarcía de Arousa",
    "23009": "Catoira",
    "23010": "Pontecesures",
    "23011": "Padrón",
    "23013": "Osebe",
    "23018": "Pontevedra Universidade",
    "23021": "Padrón-Barbanza",
    "30000": "Monfragüe",
    "30002": "Plasencia",
    "30100": "Salamanca",
    "30110": "Salamanca La Alamedilla",
    "30200": "Zamora",
    "31002": "Nava del Rey",
    "31006": "Toro",
    "31104": "Carbajales de Alba",
    "31106": "Ferreruela de Tábara",
    "31107": "Abejera",
    "31108": "Sarracín de Aliste",
    "31109": "Cabañas de Aliste",
    "31112": "Linarejos-Pedroso",
    "31200": "Puebla de Sanabria",
    "31215": "Ourense-San Francisco",
    "31303": "A Friela-Maside",
    "31304": "O Carballiño",
    "31306": "O Irixo",
    "31308": "Lalín",
    "31309": "Ponte Taboada",
    "31311": "Bandeira",
    "31312": "Vedra-Rivadulla",
    "31400": "Santiago de Compostela",
    "31411": "Uxes",
    "31412": "A Coruña",
    "31415": "Ordes",
    "31416": "Cerceda-Meirama",
    "32001": "Campillo",
    "32002": "El Carpio",
    "32003": "Fresno el Viejo",
    "32004": "Cantalapiedra",
    "32006": "El Pedroso de la Armuña",
    "32007": "Pitiegua",
    "32008": "Gomecello",
    "32009": "Moriscos",
    "34002": "Cardeñosa de Ávila",
    "34005": "San Pedro del Arroyo",
    "34007": "Crespos",
    "34008": "Narros del Castillo",
    "34009": "Entroncamento",
    "34010": "Peñaranda de Bracamonte",
    "34011": "Villar de Gallimazo",
    "34012": "Babilafuente",
    "34013": "San Morales",
    "34014": "Aldealengua",
    "35001": "Leganés",
    "35002": "Fuenlabrada",
    "35005": "Illescas",
    "35009": "Zarzaquemada",
    "35010": "La Serna-Fuenlabrada",
    "35011": "Parque Polvoranca",
    "35012": "Humanes",
    "35105": "Torrijos",
    "35107": "Erustes",
    "35109": "Montearagón",
    "35200": "Talavera de la Reina",
    "35203": "Oropesa de Toledo",
    "35206": "Navalmoral de la Mata",
    "35207": "Casatejada",
    "35301": "Mirabel",
    "35302": "Casas de Millán",
    "35303": "Cañaveral",
    "35400": "Cáceres",
    "35402": "Arroyo-Malpartida",
    "35405": "San Vicente de Alcántara",
    "35406": "Valencia de Alcántara",
    "35600": "Aluche",
    "35601": "Maestra Justa Freire-Polideportivo Aluche",
    "35602": "Las Águilas",
    "35603": "Cuatro Vientos",
    "35604": "San José de Valderas",
    "35605": "Alcorcón",
    "35606": "Móstoles",
    "35607": "Móstoles El Soto",
    "35608": "Laguna",
    "35609": "Embajadores",
    "35610": "Las Retamas",
    "35701": "Méndez Álvaro C5",
    "35702": "Doce de Octubre",
    "35703": "Orcasitas",
    "35704": "Puente Alcocer",
    "37001": "Villaverde Alto",
    "37002": "Getafe Centro",
    "37010": "Las Margaritas",
    "37011": "Getafe Sector 3",
    "37012": "Parla",
    "37200": "Ciudad Real",
    "37302": "Brazatortas-Veredas",
    "37305": "Almadenejos-Almadén",
    "37308": "Guadalmez-Los Pedroches",
    "37311": "Cabeza del Buey",
    "37400": "Almorchón",
    "37402": "Castuera",
    "37404": "Campanario",
    "37406": "Villanueva de la Serena",
    "37407": "Don Benito",
    "37409": "Valdetorres",
    "37410": "Guareña",
    "37500": "Mérida",
    "37600": "Aljucén",
    "37603": "Montijo",
    "37604": "Guadiana",
    "37606": "Badajoz",
    "37608": "Montijo El Molino",
    "37611": "Garrovilla-Las Vegas",
    "37700": "Puertollano",
    "37704": "Villanueva de Córdoba-Los Pedroches",
    "40002": "Calamonte",
    "40004": "Almendralejo",
    "40005": "Villafranca de los Barros",
    "40006": "Los Santos de Maimona",
    "40008": "Zafra Feria",
    "40100": "Zafra",
    "40105": "Llerena",
    "40107": "Fuente del Arco",
    "40108": "Guadalcanal",
    "40113": "Cazalla-Constantina",
    "40115": "Pedroso",
    "40118": "Arenillas",
    "40119": "Villanueva del Río y Minas",
    "40121": "Alcolea del Río",
    "40122": "Tocina",
    "42005": "Fregenal de la Sierra",
    "42006": "Cumbres Mayores",
    "42008": "Jabugo-Galaroza",
    "42009": "Almonaster-Cortegana",
    "42012": "Valdelamusa",
    "42013": "El Tamujoso",
    "42015": "Calañas",
    "42016": "Los Milanos",
    "42018": "El Cobujón",
    "42019": "Belmonte",
    "42020": "Gibraleón",
    "43000": "San Jerónimo",
    "43002": "Camas",
    "43003": "Villanueva del Ariscal y Olivares",
    "43004": "Sanlúcar la Mayor",
    "43005": "Benacazón",
    "43008": "Carrión de los Céspedes",
    "43009": "Escacena",
    "43011": "La Palma del Condado",
    "43012": "Villarrasa",
    "43015": "Niebla Puerta del Buey",
    "43019": "Huelva",
    "43021": "San Juan del Puerto",
    "43026": "Valencina-Santiponce",
    "43027": "Salteras",
    "50002": "Cinco Casas",
    "50100": "Manzanares",
    "50102": "Valdepeñas",
    "50200": "Santa Cruz de Mudela",
    "50202": "Almuradiel-Viso del Marqués",
    "50207": "Vilches",
    "50300": "Linares-Baeza",
    "50400": "Espelúy",
    "50403": "Andújar",
    "50407": "Villa del Río",
    "50413": "Alcolea de Córdoba",
    "50417": "Campus Universitario de Rabanales",
    "50500": "Córdoba",
    "50501": "El Higuerón",
    "50502": "Villarrubia de Córdoba",
    "50504": "Posadas",
    "50506": "Palma del Río",
    "50507": "Peñaflor",
    "50600": "Lora del Río",
    "50602": "Guadajoz",
    "50700": "Los Rosales",
    "50701": "Cantillana",
    "50702": "Brenes",
    "50703": "La Rinconada",
    "50704": "El Cáñamo",
    "50709": "Majarabique-Estación",
    "51003": "Sevilla Santa Justa",
    "51009": "Palacio de Congresos",
    "51010": "Padre Pío-Palmete",
    "51045": "Barquinha",
    "51050": "Cartuja",
    "51051": "Estadio Olímpico",
    "51100": "Sevilla San Bernardo",
    "51102": "Almourol",
    "51103": "Dos Hermanas",
    "51110": "Virgen del Rocío",
    "51111": "Bellavista",
    "51112": "Cantaelgallo",
    "51113": "Jardines de Hércules",
    "51128": "Praia Ribat",
    "51185": "St.Margarida",
    "51200": "Utrera",
    "51202": "Las Cabezas de San Juan",
    "51203": "Lebrija",
    "51205": "Aeropuerto de Jerez",
    "51243": "Tramagal",
    "51300": "Jerez de la Frontera",
    "51400": "Puerto de Santa María",
    "51401": "Puerto Real",
    "51402": "San Fernando Centro",
    "51404": "Segunda Aguada",
    "51405": "Cádiz",
    "51406": "San Fernando Bahía Sur",
    "51407": "Cortadura",
    "51409": "Estadio",
    "51414": "San Severiano",
    "51415": "Las Aletas",
    "51416": "Universidad de Cádiz",
    "51417": "Valdelagrana",
    "51419": "Río Arillo",
    "51450": "Chiclana de la Fra",
    "51451": "La Hoya",
    "51452": "R. Catolicos",
    "51453": "N. Sra. D Remedios",
    "51454": "Alameda Solano",
    "51455": "Marquesado",
    "51456": "Pinar de los Franceses",
    "51457": "Tres Caminos",
    "51458": "Venta Vargas",
    "51459": "Pza. De La Iglesia",
    "51460": "Pza. del Rey",
    "51461": "C. María",
    "51462": "Pza. del Carmen",
    "51463": "Sto.  Entierro",
    "51464": "Ardila",
    "51466": "Chiclana de la Fra",
    "52001": "Abrantes",
    "54400": "Bobadilla",
    "54403": "El Chorro-Caminito del Rey",
    "54404": "Las Mellizas",
    "54405": "Álora",
    "54406": "Pizarra",
    "54407": "Aljaima",
    "54408": "Cártama",
    "54410": "Campanillas",
    "54412": "Los Prados",
    "54413": "Málaga María Zambrano",
    "54501": "Victoria Kent",
    "54503": "Guadalhorce",
    "54505": "Aeropuerto",
    "54508": "La Colina",
    "54509": "Torremolinos",
    "54510": "El Pinillo",
    "54511": "Benalmádena-Arroyo de la Miel",
    "54512": "Torremuelle",
    "54513": "Carvajal",
    "54514": "Torreblanca del Sol",
    "54515": "Los Boliches",
    "54516": "Fuengirola",
    "54517": "Málaga Centro-Alameda",
    "54518": "Los Álamos",
    "54519": "Montemar Alto",
    "54520": "Plaza Mayor",
    "55001": "Campillos",
    "55003": "Almargen-Cañete la Real",
    "55005": "Setenil",
    "55007": "Ronda",
    "55008": "Arriate",
    "55010": "Benaoján-Montejaque",
    "55011": "Jimera de Líbar",
    "55012": "Cortes de la Frontera",
    "55013": "Gaucín",
    "55014": "San Pablo",
    "55015": "Jimena de la Frontera",
    "55017": "Almoraima",
    "55018": "San Roque-La Línea",
    "55019": "Los Barrios",
    "55020": "Algeciras",
    "55129": "Bemposta",
    "55293": "Ponte Sor",
    "56002": "Torr.Vargens",
    "56004": "Jódar-Úbeda",
    "56009": "Cabra del Santo Cristo y Alicún",
    "56100": "Moreda",
    "56101": "Chanca",
    "56103": "Benalúa de Guadix",
    "56200": "Guadix",
    "56267": "Crato",
    "56301": "Fiñana",
    "56305": "Gérgal",
    "56308": "Gádor",
    "56310": "Huercal-Viator",
    "56312": "Almería",
    "57000": "Portalegre",
    "57003": "Iznalloz",
    "57117": "Assumar",
    "57174": "Arronches",
    "57307": "Sta. Eulalia",
    "57497": "Elvas",
    "60000": "Madrid Puerta de Atocha Almudena Grandes",
    "60100": "Villaverde Bajo",
    "60101": "San Cristóbal Industrial",
    "60102": "Getafe Industrial",
    "60103": "Pinto",
    "60104": "Valdemoro",
    "60105": "Ciempozuelos",
    "60107": "San Cristóbal de los Ángeles",
    "60109": "El Casar",
    "60200": "Aranjuez",
    "60202": "Castillejo-Añover",
    "60203": "Villasequilla",
    "60204": "Huerta de Valdecarabano",
    "60206": "Tembleque",
    "60207": "El Romeral",
    "60300": "Villacañas",
    "60301": "Quero",
    "60400": "Alcázar de San Juan",
    "60402": "Campo de Criptana",
    "60406": "Socuéllamos",
    "60500": "Villarrobledo",
    "60503": "Minaya",
    "60505": "La Roda de Albacete",
    "60507": "La Gineta",
    "60600": "Albacete Los Llanos",
    "60800": "Almansa",
    "60900": "La Encina",
    "60901": "Caudete",
    "60902": "Villena",
    "60904": "Sax",
    "60905": "Elda-Petrer",
    "60907": "Novelda-Aspe",
    "60911": "Alacant / Alicante Terminal",
    "60913": "Sant Vicent Centre",
    "60914": "Universidad de Alicante",
    "61005": "Hellín",
    "61012": "Cieza",
    "61015": "Archena-Fortuna",
    "61100": "Alcantarilla",
    "61200": "Murcia del Carmen",
    "61303": "Balsicas-Mar Menor",
    "61304": "Torre-Pacheco",
    "61307": "Cartagena",
    "62001": "Beniel",
    "62002": "Orihuela Miguel Hernández",
    "62003": "Callosa de Segura",
    "62100": "San Isidro-Albatera-Catral",
    "62101": "Crevillent",
    "62102": "Elx / Elche Carrús",
    "62103": "Elx / Elche Parc",
    "62104": "Torrellano",
    "62109": "Sant Gabriel",
    "64003": "Moixent",
    "64004": "Vallada",
    "64005": "Montesa",
    "64006": "L'Alcúdia de Crespins",
    "64007": "L'Ènova-Manuel",
    "64100": "Xàtiva",
    "64102": "La Pobla Llarga",
    "64103": "Carcaixent",
    "64104": "Alzira",
    "64105": "Algemesí",
    "64107": "Benifaió",
    "64200": "Silla",
    "64201": "Catarroja",
    "64202": "Massanassa",
    "64203": "Alfafar-Benetússer",
    "65000": "València Estació del Nord",
    "65001": "Roca-Cúper",
    "65002": "València La Font de Sant Lluís",
    "65003": "València Cabanyal",
    "65005": "Albuixech",
    "65006": "Massalfassar",
    "65007": "El Puig",
    "65008": "Puçol",
    "65200": "Sagunt",
    "65201": "Les Valls",
    "65202": "Almenara",
    "65203": "La Llosa",
    "65204": "Chilches",
    "65205": "Moncofa",
    "65206": "Nules-Villavieja",
    "65207": "Burriana-Alquerías del Niño Perdido",
    "65208": "Vila-real",
    "65209": "Almassora",
    "65300": "Castelló de la Plana",
    "65304": "Orpesa",
    "65306": "Torreblanca ",
    "65308": "Alcalà de Xivert",
    "65311": "Benicarló-Peñíscola",
    "65312": "Vinaròs",
    "65314": "Ulldecona-Alcanar-La Sénia",
    "65318": "Benicàssim",
    "65400": "Tortosa",
    "65401": "Campredó",
    "65402": "L'Aldea-Amposta-Tortosa",
    "65403": "Camarles-Deltebre",
    "65404": "L'Ampolla-Perelló-Deltebre",
    "65405": "L'Ametlla de Mar",
    "65411": "Salou - Port Aventura",
    "65420": "L'Hospitalet de l'Infant",
    "65422": "Cambrils",
    "66001": "Ontígola",
    "66002": "Ocaña",
    "66003": "Noblejas",
    "66004": "Villarrubia de Santiago",
    "66005": "Santa Cruz de la Zarza",
    "66006": "Tarancón",
    "66011": "Huete",
    "66013": "Castillejo del Romeral",
    "66014": "Cuevas de Velasco",
    "66016": "Chillarón",
    "66100": "Cuenca",
    "66103": "Cañada del Hoyo",
    "66104": "Carboneras de Guadazaón",
    "66105": "Arguisuelas",
    "66108": "Yémeda-Cardenete",
    "66109": "Víllora",
    "66112": "Camporrobles",
    "66113": "Las Cuevas",
    "66200": "Utiel",
    "66201": "San Antonio de Requena",
    "66202": "Requena",
    "66203": "El Rebollar",
    "66204": "Siete Aguas",
    "66205": "Venta Mina-Siete Aguas",
    "66206": "Buñol",
    "66207": "Chiva",
    "66208": "Cheste",
    "66209": "Loriguilla-Reva",
    "66210": "Circuit Ricardo Tormo",
    "66211": "Aldaia",
    "66212": "València Sant Isidre",
    "66214": "Xirivella Alqueries",
    "67004": "María de Huerva",
    "67007": "Arañales de Muel",
    "67009": "Longares",
    "67010": "Cariñena",
    "67011": "Encinacorba",
    "67013": "Villarreal de Huerva",
    "67014": "Villadoz",
    "67015": "Badules",
    "67016": "Villahermosa",
    "67017": "Ferreruela",
    "67018": "Cuencabuena",
    "67019": "Lechago",
    "67020": "Navarrete",
    "67021": "Calamocha",
    "67100": "Caminreal-Fuentes Claras",
    "67101": "Torrijo del Campo",
    "67103": "Villafranca del Campo",
    "67105": "Santa Eulalia del Campo",
    "67107": "Cella",
    "67113": "Monreal del Campo",
    "67200": "Teruel",
    "67202": "Puerto Escandón",
    "67203": "Puebla de Valverde",
    "67205": "Sarrión",
    "67206": "Mora de Rubielos",
    "67207": "Rubielos de Mora",
    "67208": "Barracas",
    "67211": "Caudiel",
    "67212": "Jérica-Viver",
    "67213": "Navajas",
    "67214": "Segorbe Arrabal",
    "67215": "Segorbe Ciudad",
    "67216": "Algimia Ciudad",
    "67217": "Soneja",
    "67221": "Estivella-Albalat dels Tarongers",
    "67223": "Gilet",
    "69001": "Genovés",
    "69002": "Benigànim",
    "69003": "La Pobla del Duc",
    "69004": "Montaverner",
    "69005": "Bufali",
    "69006": "Albaida",
    "69007": "Agullent",
    "69008": "Ontinyent",
    "69009": "Agres",
    "69010": "Cocentaina",
    "69011": "Alcoi",
    "69101": "El Romaní",
    "69102": "Sollana",
    "69103": "Sueca",
    "69104": "Cullera",
    "69105": "Tavernes de la Valldigna",
    "69107": "Xeraco",
    "69110": "Gandia",
    "69111": "Platja i Grau de Gandia",
    "69200": "Xirivella L'Alter",
    "70002": "Asamblea de Madrid-Entrevías",
    "70003": "El Pozo",
    "70005": "Vallecas",
    "70100": "Vicálvaro",
    "70101": "San Fernando de Henares",
    "70102": "Torrejón de Ardoz",
    "70103": "Alcalá de Henares",
    "70104": "Meco",
    "70105": "Azuqueca",
    "70107": "Alcalá de Henares Universidad",
    "70108": "Coslada",
    "70109": "Santa Eugenia",
    "70111": "La Garena",
    "70112": "Soto del Henares",
    "70200": "Guadalajara",
    "70202": "Yunquera de Henares",
    "70204": "Humanes de Mohernando",
    "70207": "Espinosa de Henares",
    "70208": "Carrascosa de Henares",
    "70209": "Jadraque",
    "70210": "Matillas",
    "70300": "Baides",
    "70302": "Sigüenza",
    "70400": "Torralba",
    "70401": "Medinaceli",
    "70403": "Arcos de Jalón",
    "70404": "Santa María de Huerta",
    "70405": "Monreal de Ariza",
    "70500": "Ariza",
    "70501": "Cetina",
    "70502": "Alhama de Aragón",
    "70503": "Bubierca",
    "70504": "Ateca",
    "70505": "Terrer",
    "70600": "Calatayud",
    "70602": "Embid de Jalón",
    "70603": "Paracuellos-Sabiñán",
    "70604": "Sabiñán",
    "70605": "Morés",
    "70606": "Purroy",
    "70607": "Morata de Jalón",
    "70700": "Ricla-La Almunia",
    "70701": "Calatorao",
    "70702": "Salillas de Jalón",
    "70703": "Épila",
    "70704": "Rueda de Jalón-Lumpiaque",
    "70705": "Plasencia de Jalón",
    "70706": "Grisén",
    "70800": "Casetas",
    "70801": "Utebo",
    "70806": "Zaragoza Portillo",
    "70807": "Zaragoza Goya",
    "71100": "Zaragoza Miraflores",
    "71103": "Fuentes de Ebro",
    "71105": "Quinto",
    "71108": "La Zaida-Sástago",
    "71200": "La Puebla de Híjar",
    "71201": "Samper",
    "71204": "Caspe",
    "71205": "Val de Pilas",
    "71206": "Fabara",
    "71207": "Nonaspe",
    "71208": "Faió-La Pobla de Massaluca",
    "71209": "Riba-roja d'Ebre",
    "71210": "Flix",
    "71211": "Ascó",
    "71300": "Móra la Nova",
    "71301": "Els Guiamets",
    "71302": "Capçanes",
    "71303": "Marçà-Falset",
    "71304": "Pradell",
    "71305": "Duesaigües-L'Argentera",
    "71306": "Riudecanyes-Botarell",
    "71307": "Les Borges del Camp",
    "71400": "Reus",
    "71401": "Vila-seca",
    "71500": "Tarragona",
    "71502": "Altafulla-Tamarit",
    "71503": "Torredembarra",
    "71600": "Sant Vicenç de Calders",
    "71601": "Calafell",
    "71602": "Segur de Calafell",
    "71603": "Cunit",
    "71604": "Cubelles",
    "71700": "Vilanova i la Geltrú",
    "71701": "Sitges",
    "71703": "Garraf",
    "71704": "Platja de Castelldefels",
    "71705": "Castelldefels",
    "71706": "Gavà",
    "71707": "El Prat de Llobregat",
    "71708": "Bellvitge-Gornal",
    "71709": "Viladecans",
    "71801": "Barcelona Sants",
    "71802": "Barcelona Passeig de Gràcia",
    "72100": "Roda de Barà",
    "72101": "Roda de Mar",
    "72201": "El Vendrell",
    "72202": "L'Arboç",
    "72203": "Els Monjos",
    "72204": "Vilafranca del Penedès",
    "72205": "La Granada",
    "72206": "Lavern-Subirats",
    "72207": "Sant Sadurní d'Anoia",
    "72208": "Gelida",
    "72209": "Martorell Central",
    "72210": "Castellbisbal",
    "72211": "El Papiol",
    "72300": "Molins de Rei",
    "72301": "Sant Feliu de Llobregat",
    "72302": "Sant Joan Despí",
    "72303": "Cornellà",
    "72305": "L'Hospitalet de Llobregat",
    "72400": "Aeroport",
    "72501": "Rubí Can Vallhonrat",
    "72502": "Sant Cugat Coll Favà",
    "72503": "Cerdanyola Universitat",
    "72508": "Santa Perpètua de Mogoda Riera de Caldes",
    "73001": "Puigverd de Lleida-Artesa de Lleida",
    "73002": "Juneda",
    "73003": "Les Borges Blanques",
    "73004": "La Floresta",
    "73005": "Vinaixa",
    "73006": "Vimbodí i Poblet",
    "73007": "L'Espluga de Francolí",
    "73008": "Montblanc",
    "73009": "Vilaverd",
    "73010": "La Riba",
    "73100": "La Plana-Picamoixons",
    "73101": "Alcover",
    "73102": "La Selva del Camp",
    "74200": "Huesca",
    "74202": "Plasencia del Monte",
    "74204": "Ayerbe",
    "74205": "Riglos-Concilio",
    "74206": "Riglos",
    "74207": "Santa María y la Peña",
    "74208": "Anzánigo",
    "74209": "Caldearenas-Aquilué",
    "74211": "Sabiñánigo",
    "74213": "Jaca",
    "74214": "Castiello Pueblo",
    "74216": "Villanúa-Letranz",
    "74217": "Canfranc",
    "75101": "Alcoletge",
    "75102": "Vilanova de la Barca",
    "75103": "Termens",
    "75104": "Vallfogona de Ballaguer",
    "75105": "Balaguer",
    "75106": "Gerb",
    "75107": "Sant Llorenç de Montgai",
    "75108": "Vilanova de la Sal",
    "75109": "Santa Linya",
    "75110": "Ager",
    "75111": "Cellers-Llimiana",
    "75112": "Guardia de Tremp",
    "75113": "Palau de Noguera",
    "75114": "Tremp",
    "75115": "Salas de Pallars",
    "75116": "La Pobla de Segur",
    "76001": "Salomó",
    "76002": "Vilabella",
    "76003": "Nulles-Bràfim",
    "76004": "Valls",
    "77002": "Montcada-Ripollet",
    "77003": "Santa Perpètua de Mogoda La Florida",
    "77004": "Mollet Santa Rosa",
    "77005": "Parets del Vallès",
    "77006": "Granollers-Canovelles",
    "77100": "Les Franqueses del Vallès",
    "77102": "La Garriga",
    "77103": "Figaró",
    "77104": "Sant Martí de Centelles",
    "77105": "Centelles",
    "77106": "Balenyà Els Hostalets",
    "77107": "Balenyà-Tona-Seva",
    "77109": "Vic",
    "77110": "Manlleu",
    "77111": "Torelló",
    "77112": "Borgonyà",
    "77113": "Sant Quirze de Besora-Montesquiu",
    "77114": "La Farga de Bebié",
    "77200": "Ripoll",
    "77301": "Campdevànol",
    "77303": "Ribes de Freser",
    "77304": "Planoles",
    "77305": "Toses",
    "77306": "La Molina",
    "77307": "Urtx-Alp",
    "77309": "Puigcerdà",
    "77310": "La Tour de Carol-Enveitg",
    "78005": "Villanueva de Gállego",
    "78200": "Tardienta",
    "78201": "Grañén",
    "78203": "Sariñena",
    "78301": "Monzón-Río Cinca",
    "78302": "Binéfar",
    "78400": "Lleida Pirineus",
    "78402": "Bell-lloc d'Urgell",
    "78403": "Mollerussa",
    "78404": "Golmés",
    "78405": "Castellnou de Seana",
    "78406": "Bellpuig",
    "78407": "Anglesola",
    "78408": "Tàrrega",
    "78500": "Cervera",
    "78501": "Sant Guim de Freixenet",
    "78502": "Sant Martí Sesgueioles",
    "78503": "Calaf",
    "78504": "Seguers-Sant Pere Sallavinera",
    "78505": "Aguilar de Segarra",
    "78506": "Rajadell",
    "78600": "Manresa",
    "78604": "Sant Vicenç de Castellet",
    "78605": "Castellbell i el Vilar-Monistrol de Montserrat",
    "78606": "Vacarisses",
    "78607": "Vacarisses Torreblanca",
    "78609": "Viladecavalls",
    "78610": "Sant Miquel de Gonteres",
    "78700": "Terrassa Estació del Nord",
    "78703": "Sabadell Sud",
    "78704": "Sabadell Centre",
    "78705": "Barberà del Vallès",
    "78706": "Cerdanyola del Vallès",
    "78707": "Montcada i Reixac Santa Maria",
    "78708": "Montcada i Reixac Manresa",
    "78709": "Sabadell Nord",
    "78710": "Terrassa Est",
    "78800": "Montcada Bifurcació",
    "78801": "Barcelona Torre Baró-Vallbona",
    "78802": "Barcelona Fabra I Puig",
    "78804": "Barcelona Arc de Triomf",
    "78805": "Barcelona Plaça de Catalunya",
    "78806": "Barcelona La Sagrera-Meridiana",
    "79004": "Barcelona Sant Andreu ",
    "79005": "Montcada i Reixac",
    "79006": "Mollet-Sant Fost",
    "79007": "Montmeló",
    "79009": "Barcelona El Clot",
    "79011": "La Llagosta",
    "79100": "Granollers Centre",
    "79101": "Cardedeu",
    "79102": "Llinars del Vallès",
    "79103": "Palautordera",
    "79104": "Sant Celoni",
    "79105": "Gualba",
    "79106": "Riells i Viabrea-Breda",
    "79107": "Hostalric",
    "79109": "Les Franqueses-Granollers Nord",
    "79200": "Maçanet-Massanes",
    "79202": "Sils",
    "79203": "Caldes de Malavella",
    "79204": "Riudellots",
    "79205": "Fornells de la Selva",
    "79300": "Girona",
    "79301": "Celrà",
    "79302": "Bordils-Juià",
    "79303": "Flaçà",
    "79304": "Sant Jordi Desvalls",
    "79305": "Camallera",
    "79306": "Sant Miquel de Fluvià",
    "79308": "Vilamalla",
    "79309": "Figueres",
    "79311": "Vilajuïga",
    "79312": "Llançà",
    "79314": "Colera",
    "79315": "Portbou",
    "79316": "Cerbère",
    "79400": "Barcelona Estació de França",
    "79403": "Sant Adrià de Besòs",
    "79404": "Badalona",
    "79405": "Montgat",
    "79406": "Montgat Nord",
    "79407": "El Masnou",
    "79408": "Ocata",
    "79409": "Premià de Mar",
    "79410": "Vilassar de Mar",
    "79412": "Cabrera de Mar-Vilassar de Mar",
    "79500": "Mataró",
    "79501": "Sant Andreu de Llavaneres",
    "79502": "Caldes d'Estrac",
    "79600": "Arenys de Mar",
    "79601": "Canet de Mar",
    "79602": "Sant Pol de Mar",
    "79603": "Calella",
    "79604": "Pineda de Mar",
    "79605": "Malgrat de Mar",
    "79606": "Blanes",
    "79607": "Tordera",
    "79608": "Santa Susanna",
    "80001": "Altsasu Pueblo",
    "80003": "Etxarri Aranatz",
    "80005": "Uharte Arakil",
    "80100": "Pamplona / Iruña",
    "80108": "Tafalla",
    "80109": "Olite / Erriberri",
    "80114": "Marcilla de Navarra",
    "80115": "Villafranca de Navarra",
    "81002": "Haro",
    "81100": "Logroño",
    "81102": "Agoncillo",
    "81105": "Alcanadre",
    "81106": "Féculas-Navarra",
    "81108": "Calahorra",
    "81109": "Rincón de Soto",
    "81110": "Alfaro",
    "81200": "Castejón de Ebro",
    "81202": "Tudela de Navarra",
    "81203": "Ribaforada",
    "81205": "Cortes de Navarra",
    "81206": "Gallur",
    "81207": "Luceni",
    "81208": "Pedrola",
    "81209": "Cabañas de Ebro",
    "81210": "Alagón",
    "82100": "Soria",
    "84101": "Almazán Villa",
    "84103": "Tardelcuende",
    "84104": "Quintana Redonda",
    "87010": "Montpellier Sud de France",
    "87011": "Paris Austerlitz",
    "87013": "Paris Gare de Lyon",
    "87078": "Béziers",
    "87079": "Toulouse Matabiau",
    "87088": "Narbonne",
    "87089": "Marseille Saint Charles",
    "87173": "Montpellier Saint Roch",
    "87175": "Sète",
    "87176": "Carcassonne",
    "87287": "Agde",
    "87302": "Nîmes Centre",
    "87303": "Lyon Part Dieu",
    "87374": "Perpignan",
    "87391": "Paris Montparnasse ",
    "87810": "Valence TGV Rhône-Alps Sud",
    "87814": "Avignon TGV",
    "87912": "Aix en Provence TGV",
    "92102": "Toledo",
    "94002": "Daimiel",
    "94004": "Almagro",
    "94021": "Nine",
    "94033": "Viana do Castelo",
    "94346": "Oporto",
    "94401": "Lisboa Santa Apolonia",
    "94404": "Lisboa - Oriente",
    "94438": "Pombal",
    "94452": "Coimbra",
    "94536": "Mangualde",
    "94551": "Guarda",
    "97100": "Pitis",
    "97200": "Mirasierra Paco de Lucía",
    "97201": "Ramón y Cajal",
    "98003": "Fuente de la Mora",
    "98304": "Valdebebas",
    "98305": "Aeropuerto T4",
    "05299": "Castropol",
    "05295": "Tol",
    "05291": "La Caridad",
    "05293": "Tapia",
    "05287": "Loza",
    "05289": "Cartavio",
    "05283": "Navia",
    "05281": "Piñera-Villaoril",
    "05285": "Medal",
    "07007": "Águilas El Labradorcico",
    "07003": "Jaravía",
    "07004": "Águilas",
    "07001": "Pulpí",
    "05259": "Novellana",
    "05255": "Soto de Luiña",
    "05253": "San Cosme",
    "05257": "Valdredo",
    "05251": "San Martín de Luiña",
    "05247": "Villademar",
    "05245": "Cudillero",
    "05241": "Los Cabos",
    "05249": "La Magdalena",
    "05244": "El Pito Piñera",
    "06100": "Almendricos",
    "05279": "Villapedre",
    "05277": "Otur",
    "05243": "Muros de Nalón",
    "05273": "Barcia",
    "05275": "Luarca",
    "05269": "San Cristóbal",
    "05265": "Tablizo",
    "05267": "Cadavedo",
    "05271": "Canero",
    "05261": "Santa Marina",
    "05263": "Ballota",
    "05615": "Mar",
    "05617": "Requejada",
    "05607": "Mortera",
    "05611": "Mogro",
    "05613": "Gornazo",
    "05603": "Cazoña",
    "05605": "Bezana",
    "05609": "Boo de Piélagos",
    "05602": "Valdecilla la Marga",
    "04307": "Figueres-Vilafant",
    "05637": "Cabezón de la Sal",
    "05633": "Virgen de la Peña",
    "05635": "Ontoria",
    "05625": "Santa Isabel de Quijas",
    "05623": "Puente San Miguel",
    "05627": "San Pedro de Rudagüera",
    "05622": "Ganzo",
    "05620": "Altamira",
    "05619": "Barreda",
    "05604": "Adarzo",
    "03412": "Callosa de Segura-Cox",
    "05631": "Casar de Periedo",
    "05217": "Trasona",
    "05216": "Gudín Laminación",
    "05219": "Llaranes",
    "05629": "Golbardo",
    "05210": "Candás Apeadero",
    "05213": "Regueral",
    "05211": "Candás",
    "05691": "Gibaja",
    "05621": "Torrelavega Centro",
    "05208": "Xivares",
    "05203": "Tremañes Carreño",
    "05687": "Marrón",
    "05207": "Aboño Apeadero",
    "05209": "Perlora",
    "05685": "Limpias",
    "05689": "Udalla",
    "05683": "Treto",
    "05681": "Cicero",
    "05233": "Soto del Barco",
    "05237": "Peñaullán",
    "05232": "El Parador",
    "05235": "Riberas",
    "03410": "Elx / Elche AV",
    "05229": "Vegarrozadas",
    "05224": "Raíces",
    "05220": "Cristalería",
    "05225": "Salinas",
    "05227": "Piedras Blancas",
    "05655": "Maliaño la Vidriera",
    "05657": "Astillero",
    "05659": "San Salvador",
    "05647": "Pesués",
    "05649": "Unquera",
    "05651": "Nueva Montaña",
    "05652": "Valle Real",
    "05215": "Zanzabornín",
    "05641": "Treceño",
    "05644": "El Barcenal",
    "05643": "Roiz",
    "05679": "Gama",
    "05677": "Beranga",
    "05671": "Liérganes",
    "05673": "Villaverde de Pontones",
    "05672": "Puente Agüero",
    "05669": "La Cavada",
    "05204": "Centro de Transportes",
    "03001": "Mengíbar-Artichuela",
    "05663": "Orejo",
    "05665": "Solares",
    "05667": "Ceceñas",
    "05239": "Santianes",
    "05231": "Santiago del Monte",
    "05221": "Avilés Apeadero",
    "05658": "La Cantábrica",
    "05376": "Santa Cruz",
    "05375": "Ujo Taruelo",
    "05373": "Figaredo",
    "05377": "Caborana",
    "04040": "Zaragoza Delicias",
    "05369": "Mieres Vasco",
    "05645": "San Vicente de la Barquera",
    "05361": "Baíña",
    "05387": "Levinco",
    "05389": "Collanzo",
    "05675": "Hoz de Anero",
    "05384": "Santa Ana-Soto",
    "05381": "Oyanco",
    "05383": "Piñeres",
    "05382": "Corigos",
    "05380": "San Antonio",
    "05661": "Heras",
    "08004": "Segovia Guiomar",
    "05739": "Cabañas de Virtus",
    "05379": "Moreda de Aller",
    "05737": "Robredo Ahedo",
    "05732": "Espinosa de los Monteros",
    "05736": "Dosante Cidad",
    "05735": "Pedrosa",
    "05371": "Caudalia",
    "05733": "Redondo",
    "05730": "Bercedo-Montija",
    "05731": "Quintana de los Prados",
    "05734": "Sotoscueva",
    "05727": "Cadagua",
    "05721": "Arla Berrón",
    "05722": "Ungo Nava",
    "05759": "Prado de la Guzpeña",
    "05754": "Guardo",
    "05758": "Puente Almuhey",
    "05750": "Castrejón de la Peña",
    "05752": "Santibáñez de la Peña",
    "05753": "Guardo Apeadero",
    "05756": "La Espina",
    "05385": "Cabañaquinta",
    "05751": "Villaverde Tarilonte",
    "05747": "Cillamayor",
    "05742": "Las Rozas de Valdearroyo",
    "05744": "Los Carabeos",
    "05749": "Vado-Cervera",
    "05748": "Salinas de Pisuerga",
    "05741": "Llano",
    "05713": "Ariz",
    "04007": "Guadalajara-Yebes",
    "05325": "Pravia",
    "05327": "San Ranón",
    "05329": "San Esteban",
    "05321": "San Román",
    "05320": "Aces",
    "05738": "Soncillo",
    "05777": "Villaquilambre",
    "05772": "Pedrún",
    "05776": "San Feliz",
    "05770": "Matallana",
    "05771": "Pardavé",
    "05774": "Garrafe",
    "05724": "Mercadillo-Villasana",
    "05765": "La Losilla",
    "05764": "Barrillos",
    "05769": "La Valcueva",
    "05768": "Aviados",
    "05760": "Valle de las Casas",
    "03100": "Jaén",
    "05767": "La Vecilla",
    "05766": "Boñar",
    "05763": "La Ercina",
    "05762": "Yugueros",
    "05757": "Valcuende",
    "05317": "Grado",
    "05319": "Sandiche",
    "05755": "La Llama de la Guzpeña",
    "05316": "Peñaflor de Grado",
    "05315": "Vega de Anzo",
    "05798": "La Devesa",
    "05313": "Santa María de Grado",
    "05796": "Villa Romana",
    "05311": "Trubia",
    "05799": "Cerezal de la Guzpeña",
    "05794": "La Raya",
    "05795": "Otero",
    "05790": "Sorriba",
    "05743": "Montes Claros",
    "05303": "Las Mazas",
    "05787": "Naredo",
    "05306": "San Pedro Nora",
    "05304": "San Claudio",
    "05788": "Robles",
    "05302": "Las Campas",
    "05786": "Matueca",
    "05740": "Arija",
    "05300": "Vallobín",
    "05301": "La Argañosa-Lavapiés",
    "05497": "Karrantza",
    "05012": "Loja",
    "05323": "Beifar",
    "05493": "Villaverde de Trucios",
    "05487": "Traslaviña",
    "05481": "La Herrera",
    "05489": "Artzentales",
    "05480": "Bolunburu",
    "05000": "Granada",
    "05484": "Mimetiz",
    "05775": "Palazuelo",
    "05773": "Manzaneda",
    "05761": "Cistierna",
    "05797": "Valdepiélago",
    "05793": "La Mata de la Riba",
    "01005": "Marchena",
    "03216": "València Joaquín Sorolla",
    "01009": "Pedrera",
    "01003": "Arahal",
    "03208": "Cuenca Fernando Zóbel",
    "03213": "Requena-Utiel",
    "05308": "Soto Udrión",
    "05789": "Campohermoso",
    "05805": "Barrio de las Ollas",
    "05807": "Valderilla",
    "05785": "Villasinta",
    "05455": "Basurto Hospital",
    "05451": "Bilbao La Concordia",
    "05457": "Zorrotza-Zorrozgoiti",
    "05441": "Barredos",
    "05475": "Zalla",
    "05479": "Ibarra",
    "05470": "Lambarri",
    "05474": "Aranguren Apeadero",
    "05477": "Colegio",
    "05469": "Artxube",
    "05463": "Zaramillo",
    "05473": "Aranguren",
    "05460": "Kastrexana",
    "05417": "Carbayín",
    "05415": "Bendición",
    "05411": "La Florida",
    "05416": "Valdesoto",
    "05413": "Noreña",
    "05410": "Xixún",
    "05409": "Aguda",
    "05405": "Sotiello",
    "05407": "Pinzales",
    "05437": "Sotrondio",
    "05436": "San Martín",
    "05439": "Blimea",
    "05435": "Carrocera",
    "05431": "Ciaño Escobio",
    "05433": "El Entrego-La Oscura",
    "05485": "La Calzada",
    "05429": "Sama Los Llerones",
    "05483": "Balmaseda",
    "05425": "La Felguera Vega",
    "05420": "Curuxona",
    "05179": "Marzán",
    "05173": "Cangas de Foz",
    "05175": "Nois",
    "05169": "Madeiro",
    "05167": "San Cibrao",
    "05165": "Bidueiros",
    "05163": "Lago",
    "05161": "Xove",
    "05199": "Vilavedelle",
    "08224": "Redondela AV",
    "05197": "Vegadeo Pueblo",
    "05191": "Rinlo",
    "08223": "Vigo Urzáiz",
    "05193": "Ribadeo",
    "05189": "Os Castros",
    "05185": "Reinante",
    "05183": "Barreiros",
    "05137": "Senra",
    "05187": "Esteiro",
    "05133": "Ponte Mera",
    "05139": "Ortigueira",
    "05131": "Santa María de Mera",
    "05135": "San Clodio",
    "05127": "Cerdido",
    "01007": "Osuna",
    "05129": "A Cuqueira",
    "05123": "Labacengos",
    "05125": "Entrambarrías",
    "05121": "Moeche",
    "05803": "La Asunción-Universidad",
    "05159": "Xove Pobo",
    "06007": "Puerto Lumbreras",
    "05155": "Viveiro",
    "06002": "Alhama de Murcia",
    "06008": "Alcantarilla Los Romanos",
    "05156": "Viveiro Apeadero",
    "05157": "Xuances",
    "06005": "Lorca San Diego",
    "05151": "Folgueiro",
    "06001": "Librilla",
    "05459": "Santa Águeda",
    "05145": "O Barqueiro",
    "05147": "O Vicedo",
    "05141": "Espasante",
    "05143": "Loiba",
    "05102": "San Xoán",
    "05443": "Laviana",
    "05534": "Infiesto Apeadero",
    "05471": "Güeñes",
    "05467": "Sodupe",
    "05522": "Llames",
    "05465": "La Quadra",
    "05555": "Camango",
    "05461": "Irauregi",
    "05541": "Ozanes",
    "05412": "Puente Buracos",
    "05403": "Tremañes Langreo",
    "04104": "Camp de Tarragona",
    "05432": "San Vicente",
    "08240": "Medina del Campo AV",
    "08247": "Sanabria AV",
    "05421": "Tuilla",
    "05177": "Fazouro",
    "05171": "Burela",
    "08251": "A Gudiña Porta de Galicia",
    "05977": "Los Nietos",
    "05973": "Los Nietos Viejos",
    "05971": "El Estrecho",
    "05975": "Los Nietos Pescadería",
    "05181": "Foz",
    "05967": "Sierra Minera",
    "05969": "Llano del Beal",
    "03309": "Villena AV",
    "05965": "La Unión",
    "05961": "La Esperanza",
    "05963": "La Unión Vieja",
    "02003": "Antequera Santa Ana",
    "02002": "Puente Genil-Herrera",
    "05515": "Los Corros",
    "05511": "La Carrera de Siero",
    "05513": "Pola de Siero",
    "05517": "Lieres",
    "06006": "Lorca Sutullena",
    "05509": "El Berrón",
    "06003": "Totana",
    "05508": "Fonciello",
    "06004": "La Hoya",
    "05504": "Parque Principado",
    "05507": "Meres",
    "05505": "Colloto",
    "05153": "Covas de Viveiro",
    "05149": "Mosende",
    "05952": "Hospital",
    "05579": "Colombres",
    "05959": "Alumbres",
    "05955": "Vista Alegre",
    "05571": "Llanes",
    "05951": "Cartagena Plaza Bastarreche",
    "05953": "Media Legua",
    "05957": "Abrevadero",
    "05117": "Lamas",
    "05577": "Pendueles",
    "05573": "San Roque del Acebal",
    "05575": "Vidiago",
    "05109": "Ferrerías",
    "05567": "Celorio",
    "05569": "Poo",
    "05107": "Xuvia",
    "05563": "Posada",
    "05561": "Villahormes",
    "05565": "Balmori",
    "05119": "Apalla",
    "05535": "Villamayor",
    "05111": "Sedes",
    "05113": "Pedroso de Narón",
    "05115": "San Sadurniño",
    "05527": "Ceceda",
    "05525": "Fuente Santa de Nava",
    "05108": "O Alto do Castiñeiro",
    "05105": "Piñeiros",
    "05551": "Llovio",
    "05106": "O Ponto",
    "05103": "Santa Icía",
    "05104": "Virxe do Mar",
    "05543": "Arriondas",
    "05537": "Sebares",
    "05539": "Soto de Dueñas",
    "05531": "Pintueles",
    "05533": "Infiesto",
    "05529": "Carancos",
    "05523": "Nava",
    "05521": "El Remedio",
    "05559": "Nueva",
    "05553": "Ribadesella",
    "05545": "Fuentes",
    "05547": "Toraño",
    "05557": "Belmonte de Pría",
    "05542": "Policlínico",
    "05549": "Cuevas",
    "02030": "Antequera AV"
}