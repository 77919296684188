import React, { useState } from "react";
import { TextField, Button } from "@mui/material";

interface simulation{
    "nu_t":any, 
    "nu_a":any, 
    "est_users":any, 
    "dist_users":any,
    "timestamp":string
}

const mockSimulations:simulation[] = [
    {
        "nu_t":30, 
        "nu_a":130, 
        "est_users":214, 
        "dist_users":"x",
        "timestamp":"17:00"
    },
    {
        "nu_t":35, 
        "nu_a":120, 
        "est_users":200, 
        "dist_users":"y",
        "timestamp":"18:00"
    },
]

interface CompareProps{
    cols:number,
    simulations:any[]
}
export function Compare({cols}:CompareProps){
    return (
        <div className={`SimulationCompare ${cols === 1 ? "single": cols > 1 ? "double":"hidden"}`}>
            <div className="Card">
                <div className="Title">Comparar simulaciones</div>
                <div className="Body">
                    <div className="Simulation">
                        <div>
                            <div>Andén: <em>{mockSimulations[0].nu_a} usuarios</em></div>
                            <div>Tren: <em>{mockSimulations[0].nu_t} usuarios</em></div>
                            <div>Distribución: <em>{mockSimulations[0].dist_users}</em></div>
                        </div>
                        <div>
                            <div>Estación: <em>{mockSimulations[0].est_users} usuarios</em></div>
                            <div>Hora de simulación: <em>{mockSimulations[0].timestamp}</em></div>
                        </div>
                    </div>
                    <div className="Simulation Placeholder">
                        Seleccione una simulación para comparar
                    </div>
                </div>
            </div>
        </div>
    )
}