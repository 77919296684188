// NotificationButton.tsx
import React, { useState } from 'react';
import { Badge, IconButton, Menu, MenuItem, ListItemText, Divider, Stack } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Notification } from '../../../interfaces';
import './NotificationPanel.css'
import BasicTable from './BasicTable';
import Button from '@mui/material/Button';
interface NotificationButtonProps {
  notifications: Notification[] | [];
}

const NotificationPanel: React.FC<NotificationButtonProps> = ({ notifications }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const activeNotifications = notifications.filter(notification => notification.active);
  const orderedNotifications = notifications.sort((a, b) => b.id - a.id);

  return (
    <div className='notification-container'>
      <IconButton
        color="inherit"
        aria-label="show notifications"
        onClick={handleClick}
      >
        <Badge badgeContent={activeNotifications.length} color="error">
          <NotificationsIcon sx={{color: 'white'}}/>
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {notifications.length === 0 ? (
          <MenuItem onClick={handleClose}>
            <ListItemText primary={"No hay notificaciones activas"} />
          </MenuItem>
        ) : (

              <BasicTable notifications={orderedNotifications} />
        )}
        
        <Divider />
      </Menu>
    </div>
  );
};

export default NotificationPanel;
