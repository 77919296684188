// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-container {
    position: fixed;
    right: 0;
    top: 5rem;
    height: 3rem;
    width: 4rem;
    background-color: #00a35e;
    border-radius: 30px 0px 0px 30px;
    z-index: 2;
}
.inactive-row {
    opacity: 0.4;
    border-left: 5px solid #6f6f6f;
    font-style: italic;
  }
.active-row {
    border-left: 5px solid green;
}`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/NotificationPanel/NotificationPanel.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,QAAQ;IACR,SAAS;IACT,YAAY;IACZ,WAAW;IACX,yBAAyB;IACzB,gCAAgC;IAChC,UAAU;AACd;AACA;IACI,YAAY;IACZ,8BAA8B;IAC9B,kBAAkB;EACpB;AACF;IACI,4BAA4B;AAChC","sourcesContent":[".notification-container {\n    position: fixed;\n    right: 0;\n    top: 5rem;\n    height: 3rem;\n    width: 4rem;\n    background-color: #00a35e;\n    border-radius: 30px 0px 0px 30px;\n    z-index: 2;\n}\n.inactive-row {\n    opacity: 0.4;\n    border-left: 5px solid #6f6f6f;\n    font-style: italic;\n  }\n.active-row {\n    border-left: 5px solid green;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
