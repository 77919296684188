// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.elevators-info-container {
    width: 100% !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between;
    padding-top: 2rem !important;
    padding-left: 2rem !important;
    padding-right: 2rem !important;
}

.elevators-info {
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem !important;
}

.single-elevator-info {
    height: 4rem;
    background-color: rgb(0, 20, 52);
    display: flex;
    align-items: center;
    gap: 2rem;
    border-radius: 20px;
}

.status-marker {
    width: 3rem;
    height: 100%;
    border-radius: 20px 5px 5px 20px;;
}

.red {
    background-color: rgba(255, 87, 87, 0.889);
}

.orange {
    background-color: rgba(255, 196, 85, 0.857);
}

.green {
    background-color: rgba(85, 251, 85, 0.784);
}`, "",{"version":3,"sources":["webpack://./src/components/Dashboard/ElevatorAlerts/ElevatorAlerts.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,wBAAwB;IACxB,8BAA8B;IAC9B,8BAA8B;IAC9B,4BAA4B;IAC5B,6BAA6B;IAC7B,8BAA8B;AAClC;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,oBAAoB;AACxB;;AAEA;IACI,YAAY;IACZ,gCAAgC;IAChC,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,gCAAgC;AACpC;;AAEA;IACI,0CAA0C;AAC9C;;AAEA;IACI,2CAA2C;AAC/C;;AAEA;IACI,0CAA0C;AAC9C","sourcesContent":[".elevators-info-container {\n    width: 100% !important;\n    display: flex !important;\n    flex-direction: row !important;\n    justify-content: space-between;\n    padding-top: 2rem !important;\n    padding-left: 2rem !important;\n    padding-right: 2rem !important;\n}\n\n.elevators-info {\n    width: 45%;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    gap: 1rem !important;\n}\n\n.single-elevator-info {\n    height: 4rem;\n    background-color: rgb(0, 20, 52);\n    display: flex;\n    align-items: center;\n    gap: 2rem;\n    border-radius: 20px;\n}\n\n.status-marker {\n    width: 3rem;\n    height: 100%;\n    border-radius: 20px 5px 5px 20px;;\n}\n\n.red {\n    background-color: rgba(255, 87, 87, 0.889);\n}\n\n.orange {\n    background-color: rgba(255, 196, 85, 0.857);\n}\n\n.green {\n    background-color: rgba(85, 251, 85, 0.784);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
