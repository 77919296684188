import React, { useEffect, useState } from 'react';
import h337 from 'heatmap.js';
import map from '../../../assets/maps/Vestibulo_final.png'
import alertLogo from '../../../assets/logo/danger.png'
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import './StationMap.css'
import { DiagramProps } from '../../../interfaces';

// Función para generar un color aleatorio basado en una paleta de colores en formato RGB
function getRandomColorFromPalette(id: number) {
    const colors = [
        { r: 0, g: 152, b: 87 },
        { r: 0, g: 138, b: 107 },
        { r: 0, g: 122, b: 118 },
        { r: 0, g: 106, b: 118 },
        { r: 17, g: 88, b: 107 },
        { r: 47, g: 72, b: 88 }
    ]
    const index = id % colors.length;
    const baseColor = colors[index];

    // Añadir un ruido aleatorio basado en el entero
    const randomFactor = () => Math.floor((Math.sin(id) * 10000) % 40) - 20; // determinístico

    const newR = Math.min(255, Math.max(0, baseColor.r + randomFactor()));
    const newG = Math.min(255, Math.max(0, baseColor.g + randomFactor()));
    const newB = Math.min(255, Math.max(0, baseColor.b + randomFactor()));

    // Retornar el nuevo color RGB
    return { r: newR, g: newG, b: newB };

}

function rgbToCss(rgb: any) {
    return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`;
}

/* function getColorForTime(epochTime: number) {
    // Asegurarse de que el tiempo esté dentro del rango
    const maxTime = 30;
    let timeInMinutes = new Date(epochTime*1000).getMinutes(); // Convertir epoch time a minutos
    if (timeInMinutes < 0) timeInMinutes = 0;
    if (timeInMinutes > maxTime) timeInMinutes = maxTime;
 
    // Normalizar el tiempo en el rango [0, 1]
    const normalizedTime = timeInMinutes / maxTime;
 
    // Definir los colores de transición
    const colors = [
      { r: 0, g: 0, b: 255 },   // Azul
      { r: 0, g: 255, b: 0 },   // Verde
      { r: 255, g: 255, b: 0 }, // Amarillo
      { r: 255, g: 0, b: 0 }    // Rojo
    ];
 
    // Calcular en qué segmento de colores estamos
    const segment = normalizedTime * (colors.length - 1);
    const segmentIndex = Math.floor(segment);
    const segmentFraction = segment - segmentIndex;
 
    // Obtener los colores inicial y final para la interpolación
    const startColor = colors[segmentIndex];
    const endColor = colors[Math.min(segmentIndex + 1, colors.length - 1)];
 
    // Interpolar entre los dos colores
    const r = Math.round(startColor.r + (endColor.r - startColor.r) * segmentFraction);
    const g = Math.round(startColor.g + (endColor.g - startColor.g) * segmentFraction);
    const b = Math.round(startColor.b + (endColor.b - startColor.b) * segmentFraction);
 
    // Devolver el color en formato CSS
    return `rgb(${r}, ${g}, ${b})`;
  } */

export function VestibuloDiagram({ data, personData }: DiagramProps) {

    useEffect(() => {
        const container = document.querySelector('.vestibulo.diagram');
        if (container instanceof HTMLElement) {
            // Clear previous heatmap and image
            container.innerHTML = '';

            const heatmapInstance = h337.create({
                container,
                radius: 50,
                maxOpacity: 0.65,
                blur: 0.7,
            });

            const image = new Image();
            image.src = map;
            container.appendChild(image);

            data.map(point => {
                if (point.value >= 20) {
                    const alertContainer = document.createElement('div');
                    alertContainer.className = 'alert-container animated-icon';
                    alertContainer.style.left = `${point.x - 12}px`
                    alertContainer.style.top = `${point.y - 12}px`
                    // alertContainer.style.left = `800px`
                    // alertContainer.style.top = `470px`

                    const icon = new Image();
                    icon.src = alertLogo;
                    alertContainer.appendChild(icon);
                    container.appendChild(alertContainer);

                }
            })
            personData.map(point => {
                const alertContainer = document.createElement('div');
                alertContainer.className = 'person-container ' + point.value.toString();
                const randomColor = getRandomColorFromPalette(point.value);
                alertContainer.style.backgroundColor = rgbToCss(randomColor);
                alertContainer.style.left = `${point.x - 12}px`
                alertContainer.style.top = `${point.y - 12}px`

                container.appendChild(alertContainer);
            })
            heatmapInstance.setData({
                min: 0,
                max: 50,
                data: data
            });
        }
    }, [data]);

    return (
        <div className='diagram-container'>
            <div className="vestibulo diagram">
                <div className='alert-container'>
                    <ReportProblemIcon className="animated-icon" sx={{ color: 'yellow', fontSize: '3rem' }} />
                </div>
            </div>
        </div>
    );
}
