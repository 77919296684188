import { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { join } from "path";

interface kpis {
  values: any[];
  columns: string[];
}

export function RealTimeChart() {
  const [chartData1, setChartData1] = useState<number[]>([]);
  const [chartData2, setChartData2] = useState<number[]>([]);
  const [chartLabels, setChartLabels] = useState<string[]>([]);
  const [selectedButton, setSelectedButton] = useState("ahora");

  async function getKpis(
    start_time: number | null = null,
    end_time: number | null = null
  ) {
    try {
      var url;
      if (!start_time || !end_time) {
        url = "/api/kpis/all";
      } else {
        url = `/api/kpis?start_time=${start_time}&end_time=${end_time}`;
      }
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`Error fetching data: ${response.status}`);
      }
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error("Error fetching data:", error);
      //throw error;
    }
  }

  function parseInAndOuts(kpis: kpis) {
    try {
      const inAndOuts: any = [];
      kpis["values"].forEach((kpi: any) => {
        const item = {
          start_time: kpi[kpis["columns"].indexOf("start_time")],
          end_time: kpi[kpis["columns"].indexOf("end_time")],
          qty_people_entered:
            kpi[kpis["columns"].indexOf("qty_people_entered")],
          qty_people_left: kpi[kpis["columns"].indexOf("qty_people_left")],
        };
        inAndOuts.push(item);
      });
      return inAndOuts;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  }

  async function updateTimeFrameChart(hours: number) {
    const labels = [];
    const data1 = [];
    const data2 = [];

    // Get the current time
    let end_time = Math.floor(Date.now() / 1000);

    // Determine the interval in seconds based on the 'hours' argument
    let interval;
    switch (hours) {
      case 0:
        interval = 60; // 1 minute
        break;
      case 1:
        interval = 5 * 60; // 5 minutes
        break;
      case 3:
        interval = 20 * 60; // 20 minutes
        break;
      case 6:
        interval = 40 * 60; // 40 minutes
        break;
      default:
        throw new Error("Invalid hours argument");
    }

    // Determine the number of iterations
    let iterations = hours === 0 ? 10 : (hours * 60) / (interval / 60);

    for (let i = 0; i < iterations; i++) {
      const start_time = end_time - interval;
      let kpis = await getKpis(start_time, end_time);
      const parsedKpis = parseInAndOuts(kpis);

      let entries_sum = 0;
      let departures_sum = 0;

      if (parsedKpis.length > 0) {
        for (let i = 0; i < parsedKpis.length; i++) {
          entries_sum += parsedKpis[i].qty_people_entered;
          departures_sum += parsedKpis[i].qty_people_left;
        }
      }

      labels.push(
        new Date(end_time * 1000).toLocaleTimeString(undefined, {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        })
      );
      data1.push(entries_sum);
      data2.push(departures_sum);
      // Update end_time for the next iteration
      end_time = start_time;
    }

    setChartLabels(labels.reverse());
    setChartData1(data1.reverse());
    setChartData2(data2.reverse());
  }

  useEffect(() => {
    if (selectedButton === "ahora") {
      updateTimeFrameChart(0);
    } else if (selectedButton === "1h") {
      updateTimeFrameChart(1);
    } else if (selectedButton === "3h") {
      updateTimeFrameChart(3);
    } else if (selectedButton === "6h") {
      updateTimeFrameChart(6);
    }
  }, [selectedButton]);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setSelectedButton(newAlignment);
  };

  return (
    <div className={`Data single`}>
      <div className="Card">
        <div
          className="Title"
          style={{
            marginBottom: "2rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Entradas/Salidas de personas
          <ToggleButtonGroup
            color="primary"
            value={selectedButton}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
            sx={{
              "& .MuiToggleButton-root": {
                color: "white",
                borderColor: "white",
                width: "60px",
                textTransform: "none",
              },
              "& .Mui-selected": {
                backgroundColor: "white !important",
                color: "black !important",
              },
            }}
          >
            <ToggleButton value="ahora">Ahora</ToggleButton>
            <ToggleButton value="1h">1h</ToggleButton>
            <ToggleButton value="3h">3h</ToggleButton>
            <ToggleButton value="6h">6h</ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div className="Body" style={{ height: "100%", width: "100%" }}>
          <Line
            data={{
              labels: chartLabels,
              datasets: [
                {
                  label: "Entradas",
                  data: chartData1,
                  fill: false,
                  borderColor: "rgb(75, 192, 192)",
                  tension: 0.1,
                },
                {
                  label: "Salidas",
                  data: chartData2,
                  fill: false,
                  borderColor: "rgb(192, 75, 75)",
                  tension: 0.1,
                },
              ],
            }}
            options={{
              plugins: {
                legend: {
                  display: true,
                  labels: {
                    color: "white",
                  },
                },
              },
              scales: {
                x: {
                  ticks: {
                    color: "white",
                  },
                },
                y: {
                  ticks: {
                    color: "white",
                  },
                },
              },
              maintainAspectRatio: false,
              responsive: true,
            }}
          />
        </div>
      </div>
    </div>
  );
}
