import React from "react";

export function Footer(){
    return (
        <div className="Footer">
            <div className="copyright">
                © {new Date().getFullYear()} made by{" "}
                <a
                    href="https://es.nttdata.com/"
                    target="_blank"
                    rel="noreferrer"
                >
            NTT Data
          </a>{" "}
        </div>
        </div>
    )
}