import React from "react";

import { alerts, layerTypes } from "../../interfaces";
import { IconButton } from "@mui/material";

import Groups2Icon from '@mui/icons-material/Groups2';
import ElevatorIcon from '@mui/icons-material/Elevator';
import StairsIcon from '@mui/icons-material/Stairs';

interface SidebarProps{
    alerts:alerts,
    floor:layerTypes,
    active:boolean,
    toggle:()=>void
}

export function Sidebar({alerts, floor, active, toggle}:SidebarProps){
    return (
        <div className={`AlertsSidebar ${active ? "on":"off"}`}>
            {Object.keys(alerts.log[floor]).map((timestamp, index)=>{
                let alertType = alerts.log[floor][timestamp]
                return(
                    <div key={index} className={`Alert ${alertType}`}>
                        {timestamp} {alertType === "mass" ? <Groups2Icon />: alertType === "elevator" ? <ElevatorIcon />: <StairsIcon />}
                    </div>
                )
            })}
        </div>
    )
}