import React from "react";

import { LoginView } from "../views/views";

export function Login() {
  return (
    <div className="LoginLayout">
      <LoginView />
    </div>
  );
}
