import React from "react";
import { IconButton } from "@mui/material";

import NotificationsIcon from '@mui/icons-material/Notifications';

interface PluginProps{
    toggle:() => void,
    active:boolean
}

export function Plugin({toggle, active}:PluginProps){
    return (
        <div className={`AlertsPlugin ${active ? "on":"off"}`}>
            <IconButton onClick={()=>{toggle()}} children={<NotificationsIcon />} size="medium" sx={{color:"rgb(224, 224, 224)"}}/>
        </div>
    )
}