import React, { useState } from "react";
import { Routes, Route } from "react-router-dom"

import { DashboardView, SimulationView } from "../views/views"
import { Sidebar, Footer, Logout } from "../components/components"

export function Admin(){
    const [currentView, setCurrentView] = useState<string>("dashboard")

    const changeView = (new_view:string) => {
        setCurrentView(new_view)
    }
    return (
        <div className="AdminLayout">
            <Sidebar current_route={currentView} change_route={changeView} />
            <div className="AdminView">
            <Logout/>
                <Routes>
                    <Route path="/dashboard" element={<DashboardView />} />
                    <Route path="/simulator" element={<SimulationView />} />
                    <Route path="/" element={<DashboardView />} />
                </Routes>
                <Footer />
            </div>
        </div>
    )
}