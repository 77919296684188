import React, { useState } from "react";

import sim_result from "../../../assets/mock/Vadere.png"

interface ResultsProps {
    cols: number,
    currentSim: any | null,
    simulationKpis: any
}

export function Results({ cols, currentSim, simulationKpis }: ResultsProps) {

    return (
        <>
            {Object.keys(simulationKpis).length > 0 ? (
                <div className={`SimulationHistory ${cols === 1 ? "single" : cols > 1 ? "double" : "hidden"}`}>
                    <div className="Card">
                        <div className="Title">Resultados de la simulación</div>
                        <div className="Body">
                            <div className="Simulation" style={{display: 'flex', flexDirection: 'column', gap: '3rem', paddingLeft: '50%'}}>
                                <div>
                                    <div>Hora de simulación: <em>{simulationKpis['simulationTime']}</em></div>
                                    <div>Zona de simulación: <em>Vestíbulo</em></div>
                                    <div>Número de usuarios: <em>{simulationKpis['totalPedestrians']}</em></div>
                                </div>
                                <div>
                                    <div>Tiempo medio de evacuación: <em>{simulationKpis['evacuationAvg-PID7'].toFixed(1)} seg</em></div>
                                    <div>Tiempo de evacuación mínimo: <em>{simulationKpis['evacuationMin'].toFixed(1)} seg</em></div>
                                    <div>Tiempo de evacuación máximo: <em>{simulationKpis['evacuationMax'].toFixed(1)} seg</em></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>) : (
                <div className={`SimulationResults ${cols === 1 ? "single" : cols > 1 ? "double" : "hidden"}`}>
                    <div className="Card">
                        {currentSim && <img src={sim_result} alt="Vadere Simulation Results" />}
                        {!currentSim && <div className="SimulationPlaceholder">
                            Elija unos parámetros de simulación
                        </div>}
                    </div>
                </div>
            )}
        </>



    )
}