import { Chart } from "react-google-charts";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import { useState } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { green, grey } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: green[500],
    },
    secondary: {
      main: grey[500],
    },
  },
});

interface LineChartProps {
  name: string;
  data: any;
  timeFromSetter: (minutes: number) => void
}

export function RealTimeLineChart(props: LineChartProps) {
  const [selectedRange, setSelectedRange] = useState(5)

  function getGreatestDataValue() {
    let greatestValue = 0;
    for (let i = 0; i < props.data.length; i++) {
      if (props.data[i][1] > greatestValue) {
        greatestValue = props.data[i][1];
      }
    }
    return greatestValue;
  }

  function printLineChart() {
    return (
      <Chart
        chartType="LineChart"
        width="95%"
        height="190px"
        data={props.data}
        columns={[
          { type: "date", label: "Time" },
          { type: "number", label: "Total" }
        ]}
        options={{
          legend: { position: "none" },
          backgroundColor: "transparent",
          chartArea: { width: "85%", left: 60 },
          colors: ["white"],
          hAxis: {
            format: "hh:mm:SS",
            textStyle: { color: "#FFF" },
            gridlines: {
              count: 3,
              color: "green",
              opacity: 0.5,
            },
          },
          vAxis: {
            textStyle: { color: "#FFF" },
            title: "Número de personas",
            titleTextStyle: { color: "#FFF" },
            minValue: 0.0,
            maxValue: getGreatestDataValue() + 3,
            baseline: 4.0,
            viewWindowMode: "explicit",
            gridlines: {
              count: 2,
              color: "green",
            },
            viewWindow: {
              max: getGreatestDataValue() + 3,
              min: 0.0,
            },
          },
        }}
      />
    );
  }

  function getLastValue() {
    // get the last row of the data array
    const lastValue =
      props.data.length > 0 ? props.data[props.data.length - 1][1] : 0;
    return lastValue;
  }

  function onRangeClick(range: number) {
    setSelectedRange(range);
    props.timeFromSetter(range)
  }

  return (
    <ThemeProvider theme={theme}>
    <div className={`Data single`}>
      <div className="Card">
        <div
          className="Title"
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {props.name} 
          <ButtonGroup size="small" aria-label="Small button group">
          <Button color={selectedRange == 5 ? "primary" : "secondary"} key="one" onClick={() => onRangeClick(5)}>5min</Button>,
          <Button color={selectedRange == 15 ? "primary" : "secondary"} key="two" onClick={() => onRangeClick(15)}>15min</Button>,
          <Button color={selectedRange == 30 ? "primary" : "secondary"} key="three" onClick={() => onRangeClick(30)}>30min</Button>,
      </ButtonGroup>
          <b>{Math.floor(getLastValue())}</b>
        </div>

        <div className="chart__chart" style={{display: 'flex', justifyContent: 'center'}}>{printLineChart()}</div>
      </div>
    </div></ThemeProvider>
  );
}
