import React, { useEffect } from "react";
import { Line } from "react-chartjs-2"
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";

import { layerTypes, alertLogs } from "../../../interfaces";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend)

interface ChartProps{
    cols:number,
    floor:layerTypes,
    floors:{[key in layerTypes]:{"title":string, "map":any, "size":{"x":number,"y":number}}},
    alerts:alertLogs
}

export function Chart({cols, floor, floors, alerts}:ChartProps){
    useEffect(()=>{
        console.log(Object.values(alerts[floor].total).slice(Math.max(Object.keys(alerts[floor].total).length - 10, 0)))
    },[alerts])
    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top' as const,
          }
        },
      };
    return (
        <div className={`Data ${cols === 1 ? "single": cols > 1 ? "double":"hidden"}`}>
            <div className="Card">
                <div className="Title">Alertas durante la jornada</div>
                <div className="Subtitle">Planta: {floors[floor].title}</div>
                <div className="Body">
                    <Line options={options} data={{
                        "labels": Object.keys(alerts[floor].total).slice(Math.max(Object.keys(alerts[floor].total).length - 10, 0)),
                        "datasets":[
                            {
                                label:"Total",
                                data: Object.values(alerts[floor].total).slice(Math.max(Object.keys(alerts[floor].total).length - 10, 0)),
                                backgroundColor:"#00a35e",
                                borderColor:"#009556"
                            },
                            {
                                label:"Aglomeraciones",
                                data: Object.values(alerts[floor].mass).slice(Math.max(Object.keys(alerts[floor].mass).length - 10, 0)),
                                backgroundColor:"#b10d05",
                                borderColor:"#d81106"
                            },
                            {
                                label:"Ascensor",
                                data: Object.values(alerts[floor].elevator).slice(Math.max(Object.keys(alerts[floor].elevator).length - 10, 0)),
                                backgroundColor:"#006b95",
                                borderColor:"#0080b3"
                            },
                            {
                                label:"Escaleras",
                                data: Object.values(alerts[floor].stairs).slice(Math.max(Object.keys(alerts[floor].stairs).length - 10, 0)),
                                backgroundColor:"#27293d",
                                borderColor:"#353853"
                            },
                        ]
                    }}></Line>
                </div>
            </div>
        </div>
    )
}