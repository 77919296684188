import { Chart } from "react-google-charts";

interface ColumnChartProps {
  name: string;
  data: any;
}

export function ColumnChart(props: ColumnChartProps) {
  function getGreatestValue() {
    let greatestValue = 0;
    for (let i = 0; i < props.data.length; i++) {
      if (props.data[i][1] > greatestValue) {
        greatestValue = props.data[i][1];
      }
    }
    return greatestValue;
  }
  function printChart() {
    return (
      <Chart
        options={{
          legend: { position: "none" },
          backgroundColor: "transparent",
          chartArea: { width: "88%", height: "80%" },
          colors: ["white"],
          hAxis: {
            textStyle: { color: "#FFF" },
            gridlines: {
              count: 3,
              color: "green",
              opacity: 0.5,
            },
          },
          vAxis: {
            textStyle: { color: "#FFF" },
            minValue: 0.0,
            maxValue: getGreatestValue() + 4,
            baseline: 0,
            viewWindowMode: "explicit",
            gridlines: {
              count: 2,
              color: "green",
            },
            viewWindow: {
              max: getGreatestValue() + 3,
              min: 0.0,
            },
          },
        }}
        chartType="ColumnChart"
        width="100%"
        height="200px"
        data={props.data}
      />
    );
  }

  function getTotalGroups() {
    return props.data.length - 1;
  }
  return (
    <>
      {getTotalGroups() > 0 && (
        <>
          <div
            className="Title"
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {props.name} <b>{getTotalGroups() > 0 ? getTotalGroups() : 1}</b>
          </div>

          <div className="chart__chart">{printChart()}</div>
        </>
      )}
    </>
  );
}
