import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import { LoginLayout, AdminLayout } from "./layouts/layouts";

import "./scss/generic.scss";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
function getToken() {
  const tokenString = sessionStorage.getItem("token");

  if (!tokenString) return null;

  const userToken = JSON.parse(tokenString);
  return userToken?.token;
}

root.render(
  <>
    {!getToken() && (
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<LoginLayout />} />
        </Routes>
      </BrowserRouter>
    )}
    {getToken() && (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginLayout />} />
          <Route path="/login/*" element={<LoginLayout />} />
          <Route path="/admin/*" element={<AdminLayout />} />
          <Route
            path="*"
            element={<Navigate to="/admin/dashboard" replace />}
          />
        </Routes>
      </BrowserRouter>
    )}
  </>
);
