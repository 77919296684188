interface TrackedPerson {
  id: string,
  position: {
    x: number,
    y: number
  }
}

interface SimulationBody {
  simulation_id: string,
  scenario_file: string,
  pedestrians: TrackedPerson[]
}

export async function getDataFromFacade(path: string) {
  try {
    const response = await fetch(path);
    if (!response.ok) {
      throw new Error(`Error fetching data: ${response.status}`);
    }
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}

export async function postDataToFacade(path: string, data: SimulationBody) {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), 180000);

  try {
    const response = await fetch(path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
      signal: controller.signal,
    });

    clearTimeout(timeoutId);

    if (!response.ok) {
      throw new Error(`Error posting data: ${response.status}`);
    }

    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
}

