import { Chart } from "react-google-charts";

interface HistogramChartProps {
  name: string;
  data: any;
}

export function HistogramChart(props: HistogramChartProps) {
  function getGreatestDataValue() {
    let greatestValue = 0;
    for (let i = 1; i < props.data.length; i++) {
      // Comenzamos en 1 para evitar el encabezado
      if ((props.data[i][1] as number) > greatestValue) {
        greatestValue = parseFloat(props.data[i][1].toString());
      }
    }
    return greatestValue;
  }

  function printHistogramChart() {
    return (
      <Chart
        chartType="Histogram"
        width="95%"
        height="200px"
        data={props.data}
        options={{
          legend: "none",
          chartArea: { width: "90%", left: 70 },
          backgroundColor: "transparent",
          animation: {
            duration: 500,
            startup: true,
          },
          histogram: {
            bucketSize: 15,
            minValue: 0,
            maxValue: getGreatestDataValue(),
            hideBucketItems: true,
          },
          colors: ["white"],
          hAxis: {
            type: "category",
            title: "Tiempo (s)",
            titleTextStyle: { color: "#FFF" },
            textStyle: { color: "white" },
            gridlines: {
              count: 3,
              color: "green",
              opacity: 0.5,
            },
          },
          vAxis: {
            textStyle: { color: "#FFF" },
            title: "Número de personas",
            titleTextStyle: { color: "#FFF" },
            minValue: 0.0,
            maxValue: 40,
            baseline: 4.0,
            viewWindowMode: "explicit",
            gridlines: {
              count: 2,
              color: "green",
            },
            viewWindow: {
              min: 0.0,
            },
          },
        }}
      />
    );
  }

  function getLatestDataValue() {
    // get the last row of the data array
    const latestDataValue =
      props.data.length > 1 ? props.data[props.data.length - 1][1] : 0; // Comenzamos en 1 para evitar el encabezado
    return latestDataValue;
  }

  return (
<>
        <div
          className="Title"
          style={{
            marginBottom: "2rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {props.name} <b>{getLatestDataValue()}</b>
        </div>

        <div className="chart__chart" style={{display: 'flex', justifyContent: 'center'}}>{printHistogramChart()}</div>
</>
  );
}
