import * as React from 'react';
import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import { Notification } from '../../../interfaces';

interface BasicTableProps {
  notifications: Notification[] | [];
}

function getTypeString(type: string) {
  if(type.startsWith("zone_vestibulo_anden")) {
    let ascensor = type.replace("zone_vestibulo_anden", "");
    return "Ascensor vestibulo" + ascensor;
  }
  if(type.startsWith("zone_anden")) {
    let ascensor = type.replace("zone_anden", "");
    return "Ascensor anden" + ascensor;
  }
  return type;
}

function getRelativeDateFromTimestamp(timestamp: number) {
  let date = new Date(timestamp * 1000);
  let now = new Date();
  let diff = now.getTime() - date.getTime();
  let seconds = diff / 1000;
  let minutes = seconds / 60;
  let hours = minutes / 60;
  let days = hours / 24;
  let months = days / 30;
  let years = months / 12;

  if (hours >= 1) {
    return date.toLocaleString();
  }
  if (minutes >= 1) {
    return "Hace " + Math.floor(minutes) + " minutos";
  }
  if (seconds >= 10) {
    return "Hace " + Math.floor(seconds) + " segundos";
  }
  return "ahora";
}

export default function BasicTable(props: BasicTableProps) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Calculate the rows to display based on pagination
  const paginatedNotifications = props.notifications.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Inicio</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Datos</TableCell>
              <TableCell>Activo</TableCell>
              <TableCell>Fin</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedNotifications.map((notification) => (
              <TableRow
                key={notification.id}
                className={notification.active ? "active-row" : "inactive-row"}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {getRelativeDateFromTimestamp(notification.timestamp_started)}
                </TableCell>
                <TableCell>{getTypeString(notification.type)}</TableCell>
                <TableCell>{notification.data.qty_persons}</TableCell>
                <TableCell>{notification.active ? "Sí" : "No"}</TableCell>
                <TableCell>{notification.timestamp_ended ? getRelativeDateFromTimestamp(notification.timestamp_ended) : "-"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10]}
        component="div"
        count={props.notifications.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
