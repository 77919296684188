import React, { useEffect, useState } from "react";
import { SimulationInputs, SimulationResults, SimulationHistory, SimulationCompare } from "../components/components";

export function Simulation(){
    const [simulation, setSimulation] = useState<any | null>(null);
    const [simulationLog, setSimulationLog] = useState<any[]>([]);
    const [simulationKpis, setSimulationKpis] = useState<any>({});

    function callBack(kpis: any) {
        setSimulationKpis(kpis);
    }

    return (
        <div className="SimulationView">
            <SimulationInputs cols={1} callBack={callBack}/>
            <SimulationResults cols={1} currentSim={simulation} simulationKpis={simulationKpis}/>
            {/* <SimulationHistory cols={1} simulations={simulationLog} />
            <SimulationCompare cols={1} simulations={simulationLog}/> */}
        </div>
    )
}