import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export function Logout() {
    const navigate = useNavigate();

    const handleLogout = () => {
        sessionStorage.removeItem("token");
        navigate("/login");
        window.location.reload();
    };

    return (
        <Button
            className="LogoutButton"
            onClick={handleLogout}
            sx={{
                color: '#00a35e',
                padding: '0',
                position: 'absolute',
                display: 'flex',
                top: '5px', right: '1rem',
                textDecoration: 'underline',
            }}
        >
            Logout
        </Button>
    );
}
