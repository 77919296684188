import React, { useEffect, useState } from "react";

import { layerTypes, alerts } from "../../../interfaces";

interface DataProps {
  cols: number;
  userQty: number;
  floor: layerTypes;
  floors: {
    [key in layerTypes]: {
      title: string;
      map: any;
      size: { x: number; y: number };
    };
  };
  alerts: number;
}

interface kpi {
  id: number | string;
  start_time: string;
  end_time: string;
  zone_id: number | string;
  average_stay_time: number | string;
  density: number | string;
  qty_people_entered: number | string;
  qty_people_left: number | string;
  qty_people: number | string;
}

export function Data({ cols, userQty, floor, floors, alerts }: DataProps) {
  const [estUsers, setEstUsers] = useState(
    userQty + Math.random() * (10 + 10) - 10
  );
  const [area, setArea] = useState(floors[floor].size.x * floors[floor].size.y);
  const [lastKpi, setLastKpi] = useState<kpi>({
    id: "-",
    start_time: "-",
    end_time: "-",
    zone_id: "-",
    average_stay_time: "-",
    density: "-",
    qty_people_entered: "-",
    qty_people_left: "-",
    qty_people: "-",
  });

  useEffect(() => {
    setEstUsers(Math.floor(userQty + Math.random() * (10 + 10) - 10));
  }, [userQty]);

  useEffect(() => {
    setArea(Math.floor((floors[floor].size.x * floors[floor].size.y) / 1200));
  }, [floor]);

  useEffect(() => {
    async function fetchKpi() {
      try {
        const response = await fetch("/api/kpis/all");
        if (!response.ok) {
          throw new Error(`Error fetching data: ${response.status}`);
        }
        const jsonData = await response.json();
        if(!jsonData) {
          console.log("Error fetching KPIs")
          return
        }

        const last_value = jsonData["values"].pop();
        if (last_value) {
          const last_kpi: kpi = {
            id: last_value[jsonData["columns"].indexOf("id")],
            start_time: last_value[jsonData["columns"].indexOf("start_time")],
            end_time: last_value[jsonData["columns"].indexOf("end_time")],
            zone_id: last_value[jsonData["columns"].indexOf("zone_id")],
            average_stay_time:
              last_value[jsonData["columns"].indexOf("average_stay_time")],
            density: last_value[jsonData["columns"].indexOf("density")],
            qty_people_entered:
              last_value[jsonData["columns"].indexOf("qty_people_entered")],
            qty_people_left:
              last_value[jsonData["columns"].indexOf("qty_people_left")],
            qty_people: last_value[jsonData["columns"].indexOf("qty_people")],
          };
          return last_kpi;
        } else {
          return null;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        //throw error;
      }
    }

    const intervalId = setInterval(async () => {
      const data = await fetchKpi();
      if (data) {
        setLastKpi(data);
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  function format_date(timestamp: string) {
    try {
      if (timestamp == "-") {
        return timestamp;
      }
      const int_timestamp = parseInt(timestamp, 10);
      const date = new Date(int_timestamp * 1000);
      const formattedDate = date.toLocaleString();
      return formattedDate;
    } catch (error) {
      console.log("Error parsing date", timestamp);
    }
  }

  return (
    <div
      className={`Data ${
        cols === 1 ? "single" : cols > 1 ? "double" : "hidden"
      }`}
    >
      <div className="Card">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div className="Title">Estadísticas y alertas</div>
          <div className="Subtitle" style={{ padding: "10px 15px 0 15px" }}>
            Actualizado: {format_date(lastKpi.end_time)}
          </div>
        </div>
        <div className="Subtitle">Planta: {floors[floor].title}</div>
        <>
          <div className="Body" style={{ marginTop: "1rem" }}>
            <div className="UsersData">
              <div className="StatsTitle">USUARIOS</div>
              <div className="Stat">
                Densidad:{" "}
                <div className={`Variable ${userQty > 95 ? "alert" : ""}`}>
                  {lastKpi.density}
                </div>
                /m2
              </div>
              <div className="Stat">
                Tiempo de estancia medio:{" "}
                <div className={`Variable ${estUsers > 95 ? "alert" : ""}`}>
                  {lastKpi.average_stay_time}
                </div>
              </div>
            </div>
            <div className="AlertsData">
              <div className="StatsTitle">ALERTAS</div>
              <div className="Stat">Totales: {alerts}</div>
            </div>
          </div>
        </>
      </div>
    </div>
  );
}
