import { useState, useEffect } from 'react';
import { getDataFromFacade } from '../../../services/facade_service';
import { elevatorsData } from '../../../interfaces';
import './ElevatorAlerts.css'

const getColorClass = (value: number | null) => {
    if (value !== null) {
        if (value === 0) {
            return 'green';
        } else if (value <= 4) {
            return 'orange';
        } else {
            return 'red';
        }
    }
}

export function ElevatorAlerts() {

    const [elevators, setElevators] = useState<elevatorsData>({
        zone_vestibulo_anden9: null,
        zone_vestibulo_anden10: null,
        zone_vestibulo_anden11: null,
        zone_anden9: null,
        zone_anden10: null,
        zone_anden11: null
    })

    useEffect(() => {
        async function getZonesData() {
            try {
                const data = await getDataFromFacade('/api/zones/twins');
                const newElevatorsData = {
                    zone_vestibulo_anden9: data?.zone_vestibulo_anden9?.length | 0,
                    zone_vestibulo_anden10: data?.zone_vestibulo_anden10?.length | 0,
                    zone_vestibulo_anden11: data?.zone_vestibulo_anden11?.length | 0,
                    zone_anden9: data?.zone_anden9?.length | 0,
                    zone_anden10: data?.zone_anden10?.length | 0,
                    zone_anden11: data?.zone_anden11?.length | 0
                }
                setElevators(newElevatorsData);
            } catch (error) {
                console.log('Error fetching data: ', error);
            }
        }

        getZonesData();

        const intervalId = setInterval(() => {
            getZonesData();
        }, 5000);

        return () => clearInterval(intervalId);
    }, [])

    return (
        <>
            <div className={`Data single`}>
                <div className="Card">
                    <div className="Title" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        Alertas - Ascensores
                    </div>
                    <div className="Body" style={{ height: '90%', width: '100%' }}>
                        {
                            elevators.zone_anden10 !== null && (
                                <div className='elevators-info-container'>
                                    <div className='elevators-info'>
                                        Vestíbulo
                                        <div className='single-elevator-info'>
                                            <div className={`status-marker ${getColorClass(elevators.zone_vestibulo_anden9)}`}></div>
                                            <a>Acceso a andén 9</a>
                                        </div>
                                        <div className='single-elevator-info'>
                                            <div className={`status-marker ${getColorClass(elevators.zone_vestibulo_anden10)}`}></div>
                                            <a>Acceso a andén 10</a>
                                        </div>
                                        <div className='single-elevator-info'>
                                            <div className={`status-marker ${getColorClass(elevators.zone_vestibulo_anden11)}`}></div>
                                            <a>Acceso a andén 11</a>
                                        </div>
                                    </div>
                                    <div className='elevators-info'>
                                        Andenes
                                        <div className='single-elevator-info'>
                                            <div className={`status-marker ${getColorClass(elevators.zone_anden9)}`}></div>
                                            <a>Andén 9</a>
                                        </div>
                                        <div className='single-elevator-info'>
                                            <div className={`status-marker ${getColorClass(elevators.zone_anden10)}`}></div>
                                            <a>Andén 10</a>
                                        </div><div className='single-elevator-info'>
                                            <div className={`status-marker ${getColorClass(elevators.zone_anden11)}`}></div>
                                            <a>Andén 11</a>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>

        </>
    );
};
