import React, { useState } from "react";

import { TextField, Button, Box, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import adif_logo from "../assets/logo/logo_adif_color.png"
import ntt_logo from "../assets/logo/logo_ntt.png"

export function Login() {
  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  const navigate = useNavigate();

  const handleSubmit = async () => {
    // Perform POST request to /api/login with username and password
    // If successful, store token in sessionStorage and redirect to /admin
    // If unsuccessful, show error message
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ password: password, username: username }),
    };
    const response = await fetch("/api/login", requestOptions);
    const data = await response.json();

    if (!response.ok) {
      alert(`Login failed. Please try again. Error: ` + data.detail ? data.detail : "Unknow");
      return;
    }

    sessionStorage.setItem("token", JSON.stringify(data));

    if (!sessionStorage.getItem("token")) {
      alert("Login failed. Please try again");
      return;
    }

    navigate("/admin");
    window.location.reload();
  };

  return (
    <div className="LoginView">
          <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <div className="login-container">
      <img className="logo" src={adif_logo} alt="Logo Adif" />
      <div className="sub-logo">DIGITAL TWIN</div>
        <Stack spacing={2}>
      <TextField
        id="user"
        label="Correo"
        placeholder="@adif.es"
        variant="standard"
        onChange={(e) => {
          setUsername(e.target.value);
        }}
      />
      <TextField
        id="password"
        label="Contraseña"
        variant="standard"
        placeholder="******"
        type="password"
        onChange={(e) => {
          setPassword(e.target.value);
        }}
      />
      <Button
        variant="outlined"
        className="LoginSubmitButton"
        onClick={() => {
          handleSubmit();
        }}
      >
        Entrar
      </Button>
      </Stack>
    </div>
    <div className="footer">Powered by<a href="https://es.nttdata.com/" target="_blank"><img className="logo-ntt" src={ntt_logo} alt="Logo NTT" /></a>
    </div>
    </Box>
    </div>
  );
}
