export const limits = {
    "zona_vestibulo_completo": {
        minCamX: -20.402233,
        maxCamX: 20.919861,
        minOwnX: 210,
        maxOwnX: 870,
        minCamY: -18.3367,
        maxCamY: 18.283136,
        minOwnY: 0,
        maxOwnY: 550
    },
    "zone_vestibulo_anden9": {
        minCamX: -4.433287,
        maxCamX: 0.109934,
        minOwnX: 40,
        maxOwnX: 140,
        minCamY: -3.072177,
        maxCamY: -0.663125,
        minOwnY: 350,
        maxOwnY: 380
    },
    "zone_anden9": {
        minCamX: -3,
        maxCamX: 3,
        minOwnX: 1010,
        maxOwnX: 1080,
        minCamY: -3,
        maxCamY: 3,
        minOwnY: 120,
        maxOwnY: 240
    },
    "zone_anden10": {
        minCamX: -3,
        maxCamX: 3,
        minOwnX: 605,
        maxOwnX: 630,
        minCamY: -3,
        maxCamY: 3,
        minOwnY: 120,
        maxOwnY: 240
    },
    "zone_anden11": {
        minCamX: -3,
        maxCamX: 3,
        minOwnX: 150,
        maxOwnX: 230,
        minCamY: -3,
        maxCamY: 3,
        minOwnY: 120,
        maxOwnY: 240
    }
  }