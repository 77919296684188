import React, { useEffect, useState } from "react";
import {
  StationMap,
  Data,
  RealTimeLineChart,
  RealTimeChart,
  TrainsInfo,
  HistogramChart,
  ElevatorAlerts,
  NotificationPanel
} from "../components/components";


import {
  heatMapPoint,
  Notification
} from "../interfaces";
import { ColumnChart } from "../components/Dashboard/Charts/ColumnChart";

export function Dashboard() {
  const [clusters, setClusters] = useState({});
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [historicalTotalPpl, setHistoricalTotalPpl] = useState<any[]>([]);
  const [fromTimeMinutes, setFromTimeMinutes] = useState<number>(5)
  const [timeInZoze, setTimeInZone] = useState<any[]>([]);


  async function getHistoricalTotalPpl() {
    // Get the total number of people in the zone of the past 5 minutes
    const timestamp_from = new Date();
    timestamp_from.setMinutes(timestamp_from.getMinutes() - fromTimeMinutes);
    let int_ts = timestamp_from.getTime() / 1000;
    let api_respose = await fetch(
      "/api/kpis/historical_total_ppl?from=" + parseInt(int_ts.toString())
    );
    let data = await api_respose.json();
    if (!data || !data.values) {
      console.log("No data for historical total ppl");
      return ["Time", "Total"];
    }
    let ppl_data = data.values;

    // map to int
    ppl_data = ppl_data.map((x: any) => {
      return [new Date(x[1]), parseFloat(x[2])];
    });
    // Add a row at the beginning of the data array with the column names - ["Time", "Total"]
    ppl_data.unshift(["Time", "Total"]);

    return ppl_data;
  }
  async function getTimeInZone() {
    // Get the total number of people in the zone of the past 5 minutes
    const timestamp_from = new Date();
    timestamp_from.setMinutes(timestamp_from.getMinutes() - 60);
    let int_ts = timestamp_from.getTime() / 1000;
    let api_respose = await fetch(
      "/api/kpis/stay_time?from=" + parseInt(int_ts.toString())
    );
    let data = await api_respose.json();

    if (!data || !data.values) {
      console.log("No data for time in zone");
      return ["Time in zone"];
    }

    let time_in_zone = data.values;
    // ppl data is a list of lists, each list has the following format: [name, timestamp, total]
    // remove the first element of every list

    // map to int
    time_in_zone = time_in_zone.map((x: any) => {
      return [parseFloat(x[2])];
    });
    // Add a row at the beginning of the data array with the column names - ["Time", "Total"]
    time_in_zone.unshift(["Time in zone"]);

    return time_in_zone;
  }
  function getClusterColor(clusterId: number) {
    const colors = [
      "#009857",
      "#008A6B",
      "#007A76",
      "#006A76",
      "#11586B",
      "#2F4858"
    ];

    return colors[clusterId % colors.length];
  }
  async function getClustersData() {
    // Get the total number of clusters
    let api_respose = await fetch("/api/clusters");
    let data = await api_respose.json();

    // iterate the object and map the data
    let clusters_data = Object.keys(data).map((key) => {
      return [
        "Grupo #" + key,
        data[key]["intensity"],
        getClusterColor(parseInt(key)),
      ];
    });
    clusters_data.unshift(["Cluster", "Intensity", { role: "style" }]);

    return clusters_data;
  }

  async function getNotifications() {
    // Get all the alerts
    let api_respose = await fetch("/api/alerts/all");
    let data = await api_respose.json();

    // Parse to notifications
    let notifications : Notification[] = data.map((alert: any) => {
       let notification : Notification = {
        id: alert.id,
        timestamp_started: alert.timestamp_started,
        timestamp_ended: alert.timestamp_ended,
        type: alert.type,
        data: alert.data,
        active: alert.active
      };
      return notification;
    });
    return notifications;
  }

  async function fetchData() {
    // Get the clusters
    let clusters_data = await getClustersData();
    setClusters(clusters_data);

    // Get the total number of people in the zone of the past 5 minutes
    let ppl_data = await getHistoricalTotalPpl();

    setHistoricalTotalPpl(ppl_data);

    // get the time in zone
    let time_in_zone = await getTimeInZone();
    setTimeInZone(time_in_zone);

    // get the alerts
    let notifications = await getNotifications();
    setNotifications(notifications);
  }


  useEffect(() => {
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, 1000);
    return () => clearInterval(intervalId);
  }, [fromTimeMinutes]);
  return (
    <div className="DashboardView">
      <NotificationPanel notifications={notifications} />
      <StationMap cols={2} />
      <ElevatorAlerts />
          <div className={`Data single`}>
          <div className="Card">
      <RealTimeLineChart name="Afluencia" data={historicalTotalPpl} timeFromSetter={setFromTimeMinutes} />
      <ColumnChart name="Grupos" data={clusters} />
      </div>
      </div>
      <TrainsInfo />
      <div className={`Data single`}>
          <div className="Card">
      <HistogramChart name="Tiempo en zona" data={timeInZoze} />
      </div>
      </div>
    </div>
  );
}
